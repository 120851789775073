import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './util/reportWebVitals';
import App from "./App";
import GA4React from "ga-4-react";
import {GTM_CODE} from "./constants";
import './fonts/JohnstonITCStd/JohnstonITCStd-Bold.otf';

const ga4 = new GA4React(GTM_CODE);

(async () => {
    try {
        await ga4.initialize();
    } catch (e) {
        console.error("Error occurred while initializing google tag manager.", e);
    }
    ReactDOM.render(
        <App/>,
        document.getElementById('root')
    );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
