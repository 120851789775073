import { CHRISTMAS_THEME_START, CHRISTMAS_THEME_END } from "../constants";

const NAME_VALIDATION_REGEX = /^([a-zA-Z]+(a-za-Z|[0-9])*){2,12}$/;
const ROOM_NUMBER_VALIDATION_REGEX = /^[0-9]{1,4}$/;

export const validateName = (name) => {
  return name && NAME_VALIDATION_REGEX.test(name);
};

export const validateNameLength = (name) => {
  return name && name.length >= 2 && name.length <= 12;
};

export const validateRoomNumber = (roomNumber) => {
  return roomNumber !== null && ROOM_NUMBER_VALIDATION_REGEX.test(roomNumber);
};

export const validateParams = (roomNumber, playerName) => {
  return this.validateName(playerName) && this.validateRoomNumber(roomNumber);
};

export const isChristmasThemeActive = () => {
  const startDate = new Date(CHRISTMAS_THEME_START);
  const endDate = new Date(CHRISTMAS_THEME_END);
  if (isNaN(startDate.valueOf())) {
    return false;
  }
  if (isNaN(endDate.valueOf())) {
    return false;
  }
  const currentTime = new Date();
  return startDate < currentTime && endDate > currentTime;
};
