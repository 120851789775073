import "../css/waiting.css";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { RWebShare } from "react-web-share";
import {
  clearKeepaliveTimeout,
  closeConnection,
  getConnection,
  handleKeepalive,
  isConnectionOpen,
  sendMessage,
} from "../modules/webSocketService";
import { ReactSession as Session } from "react-client-session";
import { useGA4React } from "ga-4-react";
import WebSocket from "isomorphic-ws";
import ButtonShadowBig from "./buttonShadowBig";
import ButtonShadow from "./buttonShadow";
import {
  SESSION_KEY_GROUP_ID,
  SESSION_KEY_IS_GROUP_LEADER,
  SESSION_KEY_SESSION_ID,
  SESSION_KEY_USER_ID,
  SHARE_LINK_CAPTION_TEXT,
} from "../constants";
import wickedTile from "../images/shadowman_pink_half.png";
import niceTile from "../images/shadowman_red_half.png";
import dropShadowTile from "../images/drop_shadow_clean_tile_sq@3.png";

const CURRENT_URL = [
  window.location.protocol,
  "//",
  window.location.host,
  "/",
].join("");

function WaitingPage() {
  // Configure session store type.
  Session.setStoreType("localStorage");

  let history = useHistory();
  let location = useLocation();
  const ga4 = useGA4React();

  const [currentPlayerName] = useState(getCurrentPlayerName(location));
  const [roomNumber, setRoomNumber] = useState(getCurrentRoomNumber(location));
  const [isGroupLeader] = useState(checkGroupLeader(location));
  const [players, setPlayers] = useState([currentPlayerName]);
  const [wss, setWss] = useState(getConnection());
  const [themesState] = useState(getThemes(location));

  const [
    isValidationResultContainerHidden,
    setValidationResultContainerHidden,
  ] = useState(true);
  const [validationResult, setValidationResult] = useState("");

  useEffect(() => {
    if (ga4) {
      try {
        if (history.location.state.theme_id == "naughty-nice" ) {
          ga4.event(
              "whoofus_screen_waiting_christmas_theme",
              "Waiting screen loaded for " + location.state.theme_id
          );
        } else {
          ga4.event(
              "whoofus_screen_waiting",
              "Waiting screen loaded for " + location.state.theme_id
          );
        }
      } catch (e) {
        console.error(
          "Error occurred while recording 'whoamoungus_screen_waiting' event in Google " +
            "Analytics",
          e
        );
      }
    }
  }, []);

  useEffect(() => {
    registerResultHandler(wss);
  }, [wss, currentPlayerName, roomNumber]);

  useEffect(() => {
    switch (wss.readyState) {
      case WebSocket.OPEN:
        wss.send(
          JSON.stringify({
            action: "validate_session",
            group_id: roomNumber,
            user_id: currentPlayerName,
            auth: {
              session_id: Session.get(SESSION_KEY_SESSION_ID),
            },
          })
        );
        break;
      case WebSocket.CLOSING:
      case WebSocket.CLOSED:
        if (!isConnectionOpen(wss)) {
          setWss(getConnection());
        }
        break;
      case WebSocket.CONNECTING:
        wss.onopen = () => {
          wss.send(
            JSON.stringify({
              action: "validate_session",
              group_id: roomNumber,
              user_id: currentPlayerName,
              auth: {
                session_id: Session.get(SESSION_KEY_SESSION_ID),
              },
            })
          );
        };
    }
  }, []);

  function registerResultHandler(wss) {
    wss.onmessage = (res) => {
      if (res && res.data) {
        const data = JSON.parse(res.data);
        let action = data.action;

        clearKeepaliveTimeout();
        handleKeepalive();

        switch (action) {
          case "validate_session":
            if (data.status === true) {
              wss.send(
                JSON.stringify({
                  action: "get_users",
                  group_id: roomNumber,
                  user_id: currentPlayerName,
                  auth: {
                    session_id: Session.get(SESSION_KEY_SESSION_ID),
                  },
                })
              );
            } else {
              closeConnection();
              history.push({
                pathname: "/welcome",
                state: {},
              });
            }
            break;
          case "get_users":
            setRoomNumber(data.group_id);
            let newPlayers = data.users;
            if (newPlayers.length > 0) {
              setPlayers((prevPlayers) => {
                return [...new Set(prevPlayers.concat(newPlayers))];
              });
            }
            break;
          case "join":
            let newPlayerAsArray = data.users;
            setPlayers((prevPlayers) => {
              return [...new Set(prevPlayers.concat(newPlayerAsArray))];
            });
            setValidationResultContainerHidden(true);
            break;
          case "begin":
            if (data.error && data.error.code === 10001) {
              alert(data.error.message);
              return;
            }

            if (data.hasBegun) {
              Session.set(SESSION_KEY_GROUP_ID, roomNumber);

              if (isConnectionOpen(wss)) {
                wss.send(
                  JSON.stringify({
                    action: "get_question",
                    group_id: roomNumber,
                    currentPlayer: currentPlayerName,
                    theme_id: themesState,
                    auth: {
                      session_id: Session.get(SESSION_KEY_SESSION_ID),
                    },
                  })
                );
              }
            }
            break;
          case "get_question":
            history.push({
              pathname: "/question",
              state: {
                roomNumber: data.group_id,
                currentPlayer: currentPlayerName,
                isLoadingDone: false,
                questionType: data.type,
                theme_id: data.theme_id,
              },
            });
        }
      }
    };
  }

  function getCurrentPlayerName(location) {
    if (location.state && location.state.playerName) {
      return location.state.playerName;
    }
    return Session.get(SESSION_KEY_USER_ID) || "";
  }
  function getThemes(location) {
    if (location.state && location.state.theme_id) {
      return location.state.theme_id;
    }
    return Session.get(SESSION_KEY_USER_ID) || "";
  }
  function getCurrentRoomNumber(location) {
    if (location.state && location.state.roomNumber) {
      return location.state.roomNumber;
    }
    return Session.get(SESSION_KEY_GROUP_ID) || "";
  }

  function checkGroupLeader(location) {
    if (location.state && location.state.isGroupLeader) {
      return location.state.isGroupLeader;
    }
    let status = Session.get(SESSION_KEY_IS_GROUP_LEADER);
    return status && status === true;
  }

  const ShareButton = () => {
    return (
      <div className="col-10 col-s-8 col-sm-10 col-md-10 col-lg-8 col-xl-5 wp-share-button-container">
        <RWebShare
          data={{
            text: SHARE_LINK_CAPTION_TEXT,
            url: CURRENT_URL + "join/?roomNumber=" + roomNumber,
            title: "Who among us",
          }}
          onClick={() => {
            ga4.event(
              "whoofus_button_click_share",
              currentPlayerName + " shared the game"
            );
          }}
        >
          <button className="wtp-button wtp-share-button">
            SHARE LINK TO ROOM
          </button>
        </RWebShare>
        <ButtonShadow />
      </div>
    );
  };

  return (
    <div className="wtp-container">
      <TitleText />
      <RoomNumberLabel roomNumber={roomNumber} />
      <ShareButton />
      <SelectedTile themesState={themesState} />
      <JoinedPlayersIntroText />
      <PlayerList players={players} />
      <InstructionText />
      <BeginButton
        ga4={ga4}
        isGroupLeader={isGroupLeader}
        currentPlayerName={currentPlayerName}
        roomNumber={roomNumber}
        players={players}
        setValidationResult={setValidationResult}
        setValidationResultContainerHidden={setValidationResultContainerHidden}
        themesState={themesState}
      />
      <ValidationError
        description={validationResult}
        isHidden={isValidationResultContainerHidden}
      />
      <div
        className="col-10 col-s-8 col-sm-10 col-md-10 col-lg-8 col-xl-5 wtp-wait-inst-label"
        hidden={isGroupLeader}
      >
        Wait here while your friend starts the game.
      </div>
    </div>
  );
}

function TitleText() {
  return (
    <div className="col-10 col-s-8 col-sm-10 col-md-10 col-lg-8 col-xl-5 wtp-title-text">
      WHO
      <br />
      OF US?
    </div>
  );
}

function RoomNumberLabel({ roomNumber }) {
  return (
    <div className="wtp-room-no-div">
      ROOM# <span className="wp-room-no-span">{roomNumber}</span>
    </div>
  );
}
function SelectedTile({ themesState }) {
  return (
    <>
      {themesState === "wicked-innocent" ? (
        <>
          <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 wp-text-tile-label">
            GET READY TO PLAY...
          </div>
          <div className="col-10 col-s-8 col-sm-10 col-md-10 col-lg-8 col-xl-5 wicked-selected">
            <div className="left-wicked-shadowMan">
              <img src={wickedTile} alt="Wicked and innocent tiles" />
              <div className="cards-title">
                WICKED OR <br /> INNOCENT?
              </div>
            </div>
            <img src={dropShadowTile} className="drop-shadow" />
          </div>
        </>
      ) : (
        <></>
      )}
      {themesState === "naughty-nice" ? (
        <>
          <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 wp-text-tile-label">
            GET READY TO PLAY...
          </div>
          <div className="col-10 col-s-8 col-sm-10 col-md-10 col-lg-8 col-xl-5 naughty-selected">
            <div className="left-shadowMan">
              <img src={niceTile} alt="Nice and naughty tiles" />
              <div className="cards-title">
                NAUGHTY <br />
                OR NICE?
              </div>
            </div>
            <img src={dropShadowTile} className="drop-shadow" />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
function JoinedPlayersIntroText() {
  return (
    <div className="col-10 col-s-8 col-sm-10 col-md-10 col-lg-8 col-xl-5 wtp-text-label">
      These players have joined the game:
    </div>
  );
}

function BeginButton({
  isGroupLeader,
  currentPlayerName,
  roomNumber,
  players,
  ga4,
  setValidationResult,
  setValidationResultContainerHidden,
  themesState,
}) {
  let history = useHistory();
  let location = useLocation();
  console.log(themesState, "theme state id");
  const [disabled, setDisabled] = useState(false);
  const [buttonClass, setButtonClass] = useState("wtp-button wtp-begin-button");

  function handleClick(e) {
    e.preventDefault();

    if (players.length <= 1) {
      setValidationResult(
        "* Other players need to join the game from their devices before you begin."
      );
      setValidationResultContainerHidden(false);
      return;
    }

    if (ga4) {
      try {
        if (history.location.state.theme_id == "naughty-nice" ) {
          ga4.event(
              "whoofus_button_click_begin_game_christmas_theme",
              "Player " +
              currentPlayerName +
              " begin the game for the group " +
              roomNumber +
              " for theme " +
              history.location.state.theme_id
          );
        } else {
          ga4.event(
              "whoofus_button_click_begin_game",
              "Player " +
              currentPlayerName +
              " begin the game for the group " +
              roomNumber +
              " for theme " +
              history.location.state.theme_id
          );
        }
      } catch (e) {
        console.error(
          "Error occurred while recording 'whoamoungus_button_click_begin_game' " +
            "in Google Analytics.",
          e
        );
      }
    }

    setDisabled(true);
    setButtonClass((prevClasses) => {
      return prevClasses + " wtp-button-disabled";
    });

    sendMessage(
      {
        action: "begin",
        group_id: roomNumber,
        user_id: currentPlayerName,
        theme_id: themesState,
        auth: {
          session_id: Session.get(SESSION_KEY_SESSION_ID),
        },
      },
      (res) => {
        console.log(res.data, "begin response");
        if (res) {
          const data = JSON.parse(res.data);
          if (data.error && data.error.code === 10001) {
            alert(data.error.message);
            return;
          }
          if (data.hasBegun) {
            Session.set(SESSION_KEY_GROUP_ID, roomNumber);

            sendMessage(
              {
                action: "get_question",
                group_id: roomNumber,
                theme_id: themesState,
                currentPlayer: currentPlayerName,
                auth: {
                  session_id: Session.get(SESSION_KEY_SESSION_ID),
                },
              },
              (res1) => {
                if (res1) {
                  const data1 = JSON.parse(res1.data);

                  if (data1.action === "get_question") {
                    history.push({
                      pathname: "/question",
                      state: {
                        roomNumber: data.group_id,
                        currentPlayer: currentPlayerName,
                        isLoadingDone: false,
                        questionType: data1.type,
                        theme_id: data1.theme_id,
                      },
                    });
                  }
                }
              }
            );
          }
        }
      }
    );
  }

  return (
    <div
      className="col-10 col-s-8 col-sm-10 col-md-10 col-lg-8 col-xl-5 wtp-begin-button-container"
      hidden={!isGroupLeader}
    >
      <button
        className={buttonClass}
        disabled={disabled}
        type="button"
        onClick={handleClick}
      >
        START THE GAME
      </button>
      <ButtonShadowBig />
    </div>
  );
}

function InstructionText() {
  return (
    <div className="col-10 col-s-8 col-sm-10 col-md-10 col-lg-8 col-xl-5 wtp-text-label">
      Wait until all the players are ready before you begin the game. New
      players can't join a game that has already started.
    </div>
  );
}

function PlayerList({ players }) {
  return (
    <div className="col-10 col-s-8 col-sm-10 col-md-10 col-lg-8 col-xl-5 wtp-list-group">
      {players.map((player) => (
        <div key={player} className="wtp-list-item">
          {player}
        </div>
      ))}
    </div>
  );
}

function ValidationError({ description, isHidden }) {
  return (
    <div
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 wtp-val-cont"
      hidden={isHidden}
    >
      {description}
    </div>
  );
}

export default WaitingPage;
