import React, { useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import {
  closeConnection,
  getConnection,
  isConnectionOpen,
  isConnectionValid,
} from "../modules/webSocketService";
import { ReactSession as Session } from "react-client-session";
import { useGA4React } from "ga-4-react";
import lottie from "lottie-web";
import timerW20s from "../resources/white_empty_20s";
import timerB20s from "../resources/black_empty_20s";
import timerBW4s from "../resources/black_white_fill_4s.json";

import timerNNW20s from "../resources/Naughty_Nice/xmas_white_empty_20s";
import timerNNB20s from "../resources/Naughty_Nice/xmas_black_empty_20s";
import timerNNBW4s from "../resources/Naughty_Nice/xmas_blackwhite_fill_4s.json";
import "./../css/question.css";
import WebSocket from "isomorphic-ws";
import ButtonShadow from "./buttonShadow";
import ButtonShadowBlack from "./buttonShadowBlack";
import {
  SESSION_KEY_GROUP_ID,
  SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION,
  SESSION_KEY_SESSION_ID,
  SESSION_KEY_USER_ID,
} from "../constants";
//import {genericTap, timerClip} from "../modules/soundEffectsLoader";
import blackLeftLeaf from "../images/black_left_leaf.png";
import blackRightLeaf from "../images/black_right_leaf.png";
import whiteLeftLeaf from "../images/white_left_leaf.png";
import whiteRightLeaf from "../images/white_right_leaf.png";

const TOTAL_NUMBER_OF_QUESTIONS = 12;
const LOADING_TIMER_TICK_DURATION_IN_MILLIS = 1000;
const QUESTION_TIMER_TICK_DURATION_IN_MILLIS = 1000;
const QUESTION_TIMER_DURATION_IN_SECONDS = 20;

const PHRASES = [
  { pos: 1, text: ", of course" },
  { pos: 0, text: "That would be " },
  { pos: 1, text: ", for sure" },
  { pos: 1, text: ", obviously" },
  { pos: 0, text: "I'm dobbing in " },
  { pos: 0, text: "That's " },
  { pos: 1, text: ", clearly" },
];

const TIMER_STATE = {
  INITIALIZED: 1,
  REFRESHED_ON_EXISTING_LOADING: 2,
  REFRESHED_ON_QUESTION_LOADING: 3,
};

const QUESTION_ANSWER_STATE = {
  QUESTION_LOADED: 1,
  ALL_USERS_ANSWERED: 2,
};

const TIMEOUT_BEFORE_REVEALING_WHEN_ALL_ANSWERED_IN_MILLIS = 2500;

function QuestionPage() {
  // Configures session store type.
  Session.setStoreType("localStorage");

  const history = useHistory();
  const location = useLocation();

  const [roomNumber, setRoomNumber] = useState(getRoomNumber(location));
  const [currentPlayer] = useState(getCurrentPlayer(location));
  const [answers, setAnswers] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [currentQuestionId, setCurrentQuestionId] = useState(0);

  const [questionType, setQuestionType] = useState(
    location.state && location.state.questionType
      ? location.state.questionType
      : ""
  );
  const [isLoadingDone, setLoadingDone] = useState(
    location.state && location.state.isLoadingDone
      ? location.state.isLoadingDone
      : false
  );
  const ga4 = useGA4React();

  const [lcBlackClasses, setLCBlackClasses] = useState(
    "qp-load-inner-container-black"
  );
  const [lcWhiteClasses, setLCWhiteClasses] = useState(
    "qp-load-inner-container-white"
  );
  const [miclClasses, setMiclClasses] = useState("");
  const [micrClasses, setMicrClasses] = useState("");

  const [timerRefreshState, setTimerRefreshState] = useState(
    TIMER_STATE.INITIALIZED
  );
  const [isLoadingComponentHidden, setLoadingComponentHidden] = useState(false);
  const [timer, setTimer] = useState(null);
  const [hideInnerContainerWhite, setHideInnerContainerWhite] = useState(false);
  const [loadingTimerSeconds, setLoadingTimerSeconds] = useState(4);

  const [wss, setWss] = useState(getConnection());

  useEffect(() => {
    if (ga4) {
      ga4.event(
        "whoofus_screen_question",
        "Question screen loaded: Question '" + currentQuestion + "'"
      );
    }
    window.onpopstate = function (event) {
      history.go(1);
    };
    return () => {
      console.log("cleanup0");
    };
  }, []);

  useEffect(() => {
    if (!isConnectionOpen(wss)) {
      setWss(getConnection());
    }
    switch (wss.readyState) {
      case WebSocket.OPEN:
        wss.send(
          JSON.stringify({
            action: "validate_session",
            group_id: roomNumber,
            user_id: currentPlayer,
            auth: {
              session_id: Session.get(SESSION_KEY_SESSION_ID),
            },
          })
        );
        break;
      case WebSocket.CONNECTING:
        wss.onopen = () => {
          wss.send(
            JSON.stringify({
              action: "validate_session",
              group_id: roomNumber,
              user_id: currentPlayer,
              auth: {
                session_id: Session.get(SESSION_KEY_SESSION_ID),
              },
            })
          );
        };
        break;
      case WebSocket.CONNREFUSED:
        alert(
          "Failed to detect a valid network connection. Check your internet connectivity, " +
            "and try again."
        );
        break;
      default:
      // Do nothing.
    }
    return () => {
      console.log("cleanup1");
    };
  }, []);

  useEffect(() => {
    wss.onmessage = (res) => {
      const data = JSON.parse(res.data);
      let action = data.action;
      switch (action) {
        case "validate_session":
          if (data.status === true) {
            wss.send(
              JSON.stringify({
                action: "get_question",
                group_id: roomNumber,
                currentPlayer: currentPlayer,
                auth: {
                  session_id: Session.get(SESSION_KEY_SESSION_ID),
                },
              })
            );
          } else {
            closeConnection();
            history.push({
              pathname: "/welcome",
              state: {},
            });
          }
          break;
        case "get_users":
          setRoomNumber(data.group_id);
          let newPlayers = data.users;
          if (newPlayers.length > 0) {
            setAnswers((prevPlayers) => {
              return [...new Set(prevPlayers.concat(newPlayers))];
            });
          }
          break;
        case "join":
          let newPlayerAsArray = data.users;
          setAnswers((prevPlayers) => {
            return [...new Set(prevPlayers.concat(newPlayerAsArray))];
          });
          break;
        case "get_question":
          setCurrentQuestionId(data.questionId);
          setCurrentQuestion(data.question);
          setQuestionType(data.type);
          // setThemeId(data.theme_id);

          try {
            wss.send(
              JSON.stringify({
                action: "get_users",
                group_id: roomNumber,
                user_id: currentPlayer,
                auth: {
                  session_id: Session.get(SESSION_KEY_SESSION_ID),
                },
              })
            );
          } catch (e) {
            console.error("Error occurred while sending get_users request.", e);
          }
          break;
        default:
        // Do nothing.
      }
    };
    return () => {
      console.log("cleanup2");
    };
  }, [wss]);

  useEffect(() => {
    if (loadingTimerSeconds > 0) {
      setTimeout(() => {
        setLoadingTimerSeconds(loadingTimerSeconds - 1);
      }, LOADING_TIMER_TICK_DURATION_IN_MILLIS);
    } else {
      setTimerRefreshState(TIMER_STATE.REFRESHED_ON_EXISTING_LOADING);
      if (questionType === "innocent" || questionType === "nice") {
        setLCBlackClasses("qp-load-inner-container-black-anim");
        setMicrClasses("qp-masked-inner-container-right-anim");
      } else {
        setHideInnerContainerWhite(true);
        setLCBlackClasses("qp-load-inner-container-black");
        setLCWhiteClasses("qp-load-inner-container-white-anim");
        setMiclClasses("qp-masked-inner-container-left-anim");
      }
    }
    return () => {
      console.log("cleanup3");
    };
  }, [loadingTimerSeconds]);

  useEffect(() => {
    document.querySelector("#lod-timer-div").innerHTML = "";
    document.querySelector("#lod-timer-nn-div").innerHTML = "";

    function animation(questionType) {
      if (questionType === "nice") {
        return timerNNB20s;
      } else if (questionType === "naughty") {
        return timerNNW20s;
      } else if (questionType === "innocent") {
        return timerB20s;
      } else if (questionType === "wicked") {
        return timerW20s;
      }
    }
    switch (timerRefreshState) {
      case TIMER_STATE.INITIALIZED:
        lottie.loadAnimation({
          container:
            questionType === "innocent" || questionType === "wicked"
              ? document.querySelector("#lod-timer-div")
              : document.querySelector("#lod-timer-nn-div"),
          animationData:
            questionType === "innocent" || questionType === "wicked"
              ? timerBW4s
              : timerNNBW4s,
          loop: false,
        });
        break;
      case TIMER_STATE.REFRESHED_ON_EXISTING_LOADING:
        lottie.loadAnimation({
          container:
            questionType === "innocent" || questionType === "wicked"
              ? document.querySelector("#lod-timer-div")
              : document.querySelector("#lod-timer-nn-div"),
          animationData: animation(questionType),
          loop: false,
          autoplay: false,
        });
        break;
      case TIMER_STATE.REFRESHED_ON_QUESTION_LOADING:
        let timer = lottie.loadAnimation({
          container:
            questionType === "innocent" || questionType === "wicked"
              ? document.querySelector("#lod-timer-div")
              : document.querySelector("#lod-timer-nn-div"),
          animationData: animation(questionType),
          loop: false,
          autoplay: false,
        });
        setTimer(timer);
        break;
      default:
      // do nothing.
    }
    return () => {
      console.log("cleanup4");
    };
  }, [timerRefreshState]);

  function getRoomNumber(location) {
    if (location.state && location.state.roomNumber) {
      return location.state.roomNumber;
    }
    return Session.get(SESSION_KEY_GROUP_ID) || "";
  }

  function getCurrentPlayer(location) {
    if (location.state && location.state.currentPlayer) {
      return location.state.currentPlayer;
    }
    return Session.get(SESSION_KEY_USER_ID) || "";
  }

  function handleLoadingAnimationEnd(e) {
    e.preventDefault();
    setLoadingComponentHidden(true);
    setLoadingDone(true);
    setTimerRefreshState(TIMER_STATE.REFRESHED_ON_QUESTION_LOADING);
  }

  return (
    // <div className={"qp-container " + (questionType === "innocent" ? "qp-container-innocent" : "qp-container-wicked")}>
    <div className="qp-container">
      <div
        className={"qp-load-inner-container " + lcWhiteClasses}
        hidden={isLoadingComponentHidden}
        onAnimationEnd={handleLoadingAnimationEnd}
      />
      {questionType === "innocent" || questionType === "wicked" ? (
        <Logo
          hidden={isLoadingComponentHidden}
          miclClasses={miclClasses}
          micrClasses={micrClasses}
        />
      ) : (
        <NNLogo
          hidden={isLoadingComponentHidden}
          miclClasses={miclClasses}
          micrClasses={micrClasses}
        />
      )}

      <div id="lod-timer-div" className="qp-lod-timer" />
      <div id="lod-timer-nn-div" className="qp-lod-timer-nn" />

      <div
        className={"qp-load-inner-container " + lcBlackClasses}
        hidden={isLoadingComponentHidden || hideInnerContainerWhite}
        onAnimationEnd={handleLoadingAnimationEnd}
      />

      <QuestionSegment
        isSegmentHidden={!isLoadingDone}
        isLoadingDone={isLoadingDone}
        questionType={questionType}
        currentQuestion={currentQuestion}
        currentQuestionId={currentQuestionId}
        ga4={ga4}
        currentPlayer={currentPlayer}
        answers={answers}
        roomNumber={roomNumber}
        timer={timer}
      />
    </div>
  );
}

function NNLogo({ hidden, miclClasses, micrClasses }) {
  return (
    <div
      className="col-5 col-s-5 col-sm-5 col-md-5 col-lg-5 col-xl-2 col-xxl-2 qp-mask-container qp-masked-Xmas-logo"
      hidden={hidden}
    >
      <div className={"qp-masked-inner-container-left " + miclClasses}></div>
      <div className={"qp-masked-inner-container-right " + micrClasses}></div>
    </div>
  );
}
function Logo({ hidden, miclClasses, micrClasses }) {
  return (
    <div
      className="col-5 col-s-5 col-sm-5 col-md-5 col-lg-5 col-xl-2 col-xxl-2 qp-mask-container qp-masked-logo"
      hidden={hidden}
    >
      <div className={"qp-masked-inner-container-left " + miclClasses} />
      <div className={"qp-masked-inner-container-right" + micrClasses} />
    </div>
  );
}

function QuestionSegment({
  questionType,
  currentQuestionId,
  isLoadingDone,
  currentQuestion,
  answers,
  currentPlayer,
  roomNumber,
  isSegmentHidden,
  ga4,
  timer,
}) {
  const history = useHistory();
  const [isTitleLoaded, setTitleLoaded] = useState(false);
  const [isQuestionLoaded, setQuestionLoaded] = useState(false);
  const remainingSeconds = Session.get(
    SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION
  );
  const [seconds, setSeconds] = useState(
    remainingSeconds ? remainingSeconds : QUESTION_TIMER_DURATION_IN_SECONDS
  );
  const [answer, setAnswer] = useState();
  const [answerSent, setAnswerSent] = useState(false);
  const [isTitleHidden, setTitleHidden] = useState(true);
  const [wss, setWss] = useState(getConnection());
  const [isAnswerRevealed, setAnswerRevealed] = useState(false);
  const [answersDisabled, setDisableAnswers] = useState(false);

  useEffect(() => {
    if (isLoadingDone) {
      setTitleHidden(false);
    }
  }, [isLoadingDone]);

  useEffect(() => {
    if (isQuestionLoaded) {
      timer.play();
    }
  }, [isQuestionLoaded]);

  useEffect(() => {
    registerResultHandler(wss);
  }, [wss]);

  useEffect(() => {
    let timeout;
    if (isQuestionLoaded) {
      if (seconds > 0) {
        // if (seconds === 5) {
        //     timerClip.play();
        // }
        timeout = setTimeout(() => {
          let remaining = seconds - 1;
          setSeconds(remaining);
          if (!answerSent) {
            Session.set(SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION, remaining);
          } else {
            Session.set(
              SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION,
              QUESTION_TIMER_DURATION_IN_SECONDS
            );
          }
        }, QUESTION_TIMER_TICK_DURATION_IN_MILLIS);
      } else {
        setDisableAnswers(true);
        Session.set(
          SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION,
          QUESTION_TIMER_DURATION_IN_SECONDS
        );

        if (!isConnectionValid(wss)) {
          setWss(getConnection());
          registerResultHandler(wss);
        }

        switch (wss.readyState) {
          case WebSocket.OPEN:
            wss.send(
              JSON.stringify({
                action: "send_answer",
                group_id: roomNumber,
                user_id: currentPlayer,
                current_question_id: currentQuestionId,
                answer: answer,
                auth: {
                  session_id: Session.get(SESSION_KEY_SESSION_ID),
                },
              })
            );
            break;
          case WebSocket.CONNECTING:
            wss.onopen = () => {
              wss.send(
                JSON.stringify({
                  action: "send_answer",
                  group_id: roomNumber,
                  user_id: currentPlayer,
                  current_question_id: currentQuestionId,
                  answer: answer,
                  auth: {
                    session_id: Session.get(SESSION_KEY_SESSION_ID),
                  },
                })
              );
            };
            break;
          case WebSocket.CONNREFUSED:
            alert(
              "Failed to detect a valid network connection. Check your internet connectivity, " +
                "and try again."
            );
            // case WebSocket.CLOSING:
            // case WebSocket.CLOSED:
            //     alert("Network connection was interrupted. Check your internet " +
            //         "connectivity and try again.");
            break;
          default:
          // Do nothing.
        }
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isQuestionLoaded, seconds]);

  function registerResultHandler(wss) {
    wss.onmessage = (res) => {
      const data = JSON.parse(res.data);
      setAnswerRevealed(true);
      setDisableAnswers(true);

      history.push({
        pathname: "/reveal",
        state: {
          currentQuestion: data.current_question,
          currentQuestionId: currentQuestionId,
          result: data.result,
          groupId: roomNumber,
          questionType: questionType,
          numCorrectAnswers: data.num_correct_answers,
          numAnswers: data.num_answers,
          correctAnswerPercentage: data.correct_answer_percentage,
          description: data.description,
          currentPlayer: currentPlayer,
          allUsersAnswered: data.allUsersAnswered,
        },
      });
    };
  }

  return (
    <div
      className={
        (questionType === "innocent" || questionType === "nice"
          ? "qp-qseg-innocent"
          : "") +
        (questionType === "wicked" || questionType === "naughty"
          ? "qp-qseg-wicked"
          : "")
      }
      hidden={isSegmentHidden}
    >
      {questionType === "innocent" || questionType === "wicked" ? (
        <TitleText
          questionType={questionType}
          isHidden={isTitleHidden}
          setTitleLoaded={setTitleLoaded}
        />
      ) : (
        <TitleLogo
          questionType={questionType}
          isHidden={isTitleHidden}
          setTitleLoaded={setTitleLoaded}
        />
      )}

      <QuestionNumberCircle
        questionType={questionType}
        currentQuestionId={currentQuestionId}
        totalNumberOfQuestions={TOTAL_NUMBER_OF_QUESTIONS}
        isHidden={isTitleHidden}
      />
      <Question
        questionType={questionType}
        currentQuestion={currentQuestion}
        isHidden={!isTitleLoaded}
        setQuestionLoaded={setQuestionLoaded}
      />
      <AnswerList
        answers={answers}
        currentPlayer={currentPlayer}
        groupId={roomNumber}
        currentQuestionId={currentQuestionId}
        setAnswer={setAnswer}
        setAnswerSent={setAnswerSent}
        ga4={ga4}
        questionType={questionType}
        isHidden={!isQuestionLoaded}
        isAnswerRevealed={isAnswerRevealed}
        disableAnswers={answersDisabled}
      />
    </div>
  );
}

function QuestionNumberCircle({
  questionType,
  currentQuestionId,
  totalNumberOfQuestions,
  isHidden,
}) {
  return (
    <div
      className={
        questionType === "innocent" || questionType === "wicked"
          ? "question-number-circle "
          : (questionType === "nice" ? "nn-green " : "nn-red ") +
            "col - 10 col-s-10 col-sm-10 col-md-10 col-lg-8"
      }
      hidden={isHidden}
    >
      {currentQuestionId + "/" + totalNumberOfQuestions}
    </div>
  );
}

function TitleText({ questionType, isHidden, setTitleLoaded }) {
  useEffect(() => {
    return () => {
      if (isHidden) {
        setTitleLoaded(true);
      }
    };
  }, [isHidden]);

  return (
    <div
      className={
        "col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 qp-title " +
        (questionType === "wicked" ? "qp-title-wicked " : "") +
        (questionType === "innocent" ? "qp-title-innocent " : "")
      }
      hidden={isHidden}
    >
      WHO
      <br />
      OF US
    </div>
  );
}

function TitleLogo({ questionType, isHidden, setTitleLoaded }) {
  useEffect(() => {
    return () => {
      if (isHidden) {
        setTitleLoaded(true);
      }
    };
  }, [isHidden]);
  return (
    <div
      className={
        "col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 qp-title "
      }
      hidden={isHidden}
    >
      {questionType === "nice" ? (
        <>
          <img src={blackLeftLeaf} className="qp-title-nice " />
          <span className="qp-logo-nice">
            WHO
            <br />
            OF US
          </span>
          <img src={blackRightLeaf} className="qp-title-nice " />
        </>
      ) : (
        <></>
      )}
      {questionType === "naughty" ? (
        <>
          <img src={whiteLeftLeaf} className="qp-title-naughty " />
          <span className="qp-logo-naughty">
            WHO
            <br />
            OF US
          </span>
          <img src={whiteRightLeaf} className="qp-title-naughty " />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

function Question({
  questionType,
  currentQuestion,
  isHidden,
  setQuestionLoaded,
}) {
  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 qp-ques-cont">
      <div
        className={
          questionType === "innocent" || questionType === "wicked"
            ? "qp-question"
            : questionType === "nice"
            ? "qp-black"
            : "qp-white"
        }
        hidden={isHidden}
        onAnimationEnd={() => setQuestionLoaded(true)}
      >
        {currentQuestion}
      </div>
    </div>
  );
}

function AnswerList({
  answers,
  currentPlayer,
  groupId,
  currentQuestionId,
  setAnswer,
  setAnswerSent,
  genericTap,
  ga4,
  questionType,
  isHidden,
  isAnswerRevealed,
  disableAnswers,
}) {
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [selectedAnswerButtonText, setSelectedAnswerButtonText] = useState("");
  const [answerStatuses] = useState({});
  const [qaState, setQAState] = useState(QUESTION_ANSWER_STATE.QUESTION_LOADED);

  useEffect(() => {
    answers.forEach((answer) => {
      answerStatuses[answer] = answer === selectedAnswer;
      setSelectedAnswerButtonText(generateResultDescription(selectedAnswer));
    });
  }, [selectedAnswer]);

  function getButtonClass(answer) {
    let buttonClass = "qp-answer-button ";
    if (answer === selectedAnswer && questionType === "innocent") {
      buttonClass += "qp-answer-button-innocent-clicked";
    } else if (answer === selectedAnswer && questionType === "wicked") {
      buttonClass += "qp-answer-button-wicked-clicked";
    } else if (answer !== selectedAnswer && questionType === "innocent") {
      buttonClass += "qp-answer-button-innocent";
    } else if (answer !== selectedAnswer && questionType === "wicked") {
      buttonClass += "qp-answer-button-wicked";
    } else if (answer === selectedAnswer && questionType === "nice") {
      buttonClass += "qp-answer-button-nice-clicked";
    } else if (answer === selectedAnswer && questionType === "naughty") {
      buttonClass += "qp-answer-button-naughty-clicked";
    } else if (answer !== selectedAnswer && questionType === "nice") {
      buttonClass += "qp-answer-button-nice";
    } else if (answer !== selectedAnswer && questionType === "naughty") {
      buttonClass += "qp-answer-button-naughty";
    }

    if (
      qaState === QUESTION_ANSWER_STATE.ALL_USERS_ANSWERED &&
      answer !== selectedAnswer
    ) {
      buttonClass += " qp-answer-button-hide";
    } else if (
      qaState === QUESTION_ANSWER_STATE.ALL_USERS_ANSWERED &&
      answer === selectedAnswer
    ) {
      buttonClass +=
        " " +
        (questionType === "innocent"
          ? " qp-answer-button-innocent-anim"
          : "" + questionType === "wicked"
          ? " qp-answer-button-wicked-anim"
          : "") +
        (questionType === "nice"
          ? " qp-answer-button-nice-anim"
          : "" + questionType === "naughty"
          ? " qp-answer-button-naughty-anim"
          : "");
    } else if (isAnswerRevealed && answer === selectedAnswer) {
      buttonClass +=
        " " +
        (questionType === "innocent"
          ? " qp-answer-button-innocent-anim"
          : "" + questionType === "wicked"
          ? " qp-answer-button-wicked-anim"
          : "") +
        (questionType === "nice"
          ? " qp-answer-button-nice-anim"
          : "" + questionType === "naughty"
          ? " qp-answer-button-naughty-anim"
          : "");
    } else if (
      isAnswerRevealed &&
      selectedAnswer &&
      answer !== selectedAnswer
    ) {
      buttonClass += " qp-answer-button-hide";
    }
    return buttonClass;
  }

  function getButtonStyle(i) {
    return { animationDelay: 0.1 * (i + 1) + "s" };
  }

  return (
    <div
      className={
        "col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 qp-stack " +
          (questionType === "wicked" ? "qp-stack-wicked" : "") ||
        (questionType === "naughty" ? "qp-stack-naughty" : "")
      }
      hidden={isHidden}
    >
      {answers.map((answer, i) => (
        <Answer
          key={answer}
          groupId={groupId}
          currentPlayer={currentPlayer}
          currentQuestionId={currentQuestionId}
          answer={answer}
          setAnswer={setAnswer}
          setAnswerSent={setAnswerSent}
          setSelectedAnswer={setSelectedAnswer}
          isDisabled={answerStatuses[answer] || disableAnswers}
          buttonText={
            answer === selectedAnswer ? selectedAnswerButtonText : answer
          }
          genericTap={genericTap}
          ga4={ga4}
          buttonClass={getButtonClass(answer)}
          buttonContainerClass={
            qaState === QUESTION_ANSWER_STATE.ALL_USERS_ANSWERED &&
            answer !== selectedAnswer
              ? "qp-button-container-hide"
              : ""
          }
          style={getButtonStyle(i)}
          questionType={questionType}
          setQAState={setQAState}
        />
      ))}
    </div>
  );
}

function Answer({
  groupId,
  currentPlayer,
  currentQuestionId,
  answer,
  isDisabled,
  setAnswer,
  setAnswerSent,
  setSelectedAnswer,
  buttonText,
  genericTap,
  ga4,
  buttonClass,
  style,
  setQAState,
  questionType,
  buttonContainerClass,
}) {
  const history = useHistory();
  const [wss, setWss] = useState(getConnection());

  useEffect(() => {
    registerResultHandler(wss);
  }, [wss]);

  function handleClick(event) {
    event.preventDefault();
    if (ga4) {
      ga4.event(
        "whoofus_button_click_answer",
        currentPlayer + " selected the answer " + answer
      );
    }

    // Plays a sound clip when a user selects an answer.
    // if (genericTap) {
    //     genericTap.play();
    // }

    const selectedAnswer = event.target.id;
    setAnswer(selectedAnswer);
    setSelectedAnswer(selectedAnswer);
    setAnswerSent(true);
    Session.set(
      SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION,
      QUESTION_TIMER_DURATION_IN_SECONDS
    );

    // Waits for a second until the game participant sees the button text change to improve
    // user experience.
    setTimeout(() => {
      if (!isConnectionValid(wss)) {
        setWss(getConnection());
        registerResultHandler(wss);
      }
      switch (wss.readyState) {
        case WebSocket.OPEN:
          wss.send(
            JSON.stringify({
              action: "send_answer",
              group_id: groupId,
              user_id: currentPlayer,
              current_question_id: currentQuestionId,
              answer: selectedAnswer,
              auth: {
                session_id: Session.get(SESSION_KEY_SESSION_ID),
              },
            })
          );
          break;
        case WebSocket.CONNECTING:
          wss.onopen = () => {
            wss.send(
              JSON.stringify({
                action: "send_answer",
                group_id: groupId,
                user_id: currentPlayer,
                current_question_id: currentQuestionId,
                answer: selectedAnswer,
                auth: {
                  session_id: Session.get(SESSION_KEY_SESSION_ID),
                },
              })
            );
          };
          break;
        case WebSocket.CONNREFUSED:
          alert(
            "Failed to detect a valid network connection. Check your internet connectivity, " +
              "and try again."
          );
          break;
        default:
        // Do nothing.
      }
    }, 800);
  }

  function registerResultHandler(wss) {
    wss.onmessage = (res) => {
      const data = JSON.parse(res.data);
      if (data.allUsersAnswered === true) {
        setQAState(QUESTION_ANSWER_STATE.ALL_USERS_ANSWERED);
        setTimeout(() => {
          history.push({
            pathname: "/reveal",
            state: {
              currentQuestion: data.current_question,
              currentQuestionId: currentQuestionId,
              result: data.result,
              groupId: groupId,
              questionType: questionType,
              numCorrectAnswers: data.num_correct_answers,
              numAnswers: data.num_answers,
              correctAnswerPercentage: data.correct_answer_percentage,
              description: data.description,
              currentPlayer: currentPlayer,
              allUsersAnswered: data.allUsersAnswered,
            },
          });
        }, TIMEOUT_BEFORE_REVEALING_WHEN_ALL_ANSWERED_IN_MILLIS);
      } else {
        history.push({
          pathname: "/reveal",
          state: {
            currentQuestion: data.current_question,
            currentQuestionId: currentQuestionId,
            result: data.result,
            groupId: groupId,
            questionType: questionType,
            numCorrectAnswers: data.num_correct_answers,
            numAnswers: data.num_answers,
            correctAnswerPercentage: data.correct_answer_percentage,
            description: data.description,
            currentPlayer: currentPlayer,
            allUsersAnswered: data.allUsersAnswered,
          },
        });
      }
    };
  }

  if (questionType === "wicked" || questionType === "naughty") {
    return (
      <div className={"qp-button-wrapper " + buttonContainerClass}>
        <button
          className={buttonClass}
          id={answer}
          key={answer}
          disabled={isDisabled}
          onClick={handleClick}
          style={style}
        >
          {buttonText}
        </button>
        <ButtonShadowBlack />
      </div>
    );
  }

  return (
    <div className={"qp-button-wrapper " + buttonContainerClass}>
      <button
        className={buttonClass}
        id={answer}
        key={answer}
        disabled={isDisabled}
        onClick={handleClick}
        style={style}
      >
        {buttonText}
      </button>
      <ButtonShadow />
    </div>
  );
}

function generateResultDescription(answer) {
  if (!answer) {
    return null;
  }
  const phraseId = Math.floor(Math.random() * (PHRASES.length - 1));
  const phrase = PHRASES[phraseId];
  if (phrase.pos === 0) {
    return phrase.text + answer + "!";
  } else {
    return answer + phrase.text + "!";
  }
}

export default withRouter(QuestionPage);
