import "../css/start.css";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { sendMessage } from "../modules/webSocketService";
import { useAuth } from "../modules/authenticator";
import { ReactSession as Session } from "react-client-session";
import { useGA4React } from "ga-4-react";
import { validateNameLength } from "../util/util";
import logoImage from "../images/logo.png";
import ButtonShadow from "./buttonShadow";
import { SESSION_KEY_SESSION_ID } from "../constants";
import naughtyOrNice from "../images/shadowman_naughty_nice.png";
import wickedOrInnocent from "../images/Shadowman_pink.png";
import dropStartShadowTile from "../images/drop_shadow_small_tile.png";
import { isChristmasThemeActive } from "../util/util";
function StartPage() {
  // Configures session store type.
  Session.setStoreType("localStorage");

  // let auth = useAuth();

  const ga4 = useGA4React();
  const [playerName, setPlayerName] = useState("");
  const [
    isValidationResultContainerHidden,
    setValidationResultContainerHidden,
  ] = useState(true);
  const [validationResult, setValidationResult] = useState("");
  const [cardValidationResult, setCardValidationResult] = useState("");
  const [
    isCardValidationResultContainerHidden,
    setCardValidationResultContainerHidden,
  ] = useState(true);
  const [dropshadow, setDropshadow] = useState(false);
  const [themes, setThemes] = useState({
    activeCard: null,
    themeList: [
      {
        id: 1,
        theme_id: "wicked-innocent",
        imgUrl: wickedOrInnocent,
      },
      {
        id: 2,
        theme_id: "naughty-nice",
        imgUrl: naughtyOrNice,
      },
    ],
  });

  const toggleActive = (index) => {
    setThemes({
      ...themes,
      activeCard: themes.themeList[index],
    });
    if (themes.themeList[index]) {
      setCardValidationResult(null);
      setCardValidationResultContainerHidden(true);
    }
    if (themes.themeList[index]) {
      setDropshadow(true);
    }
    if (ga4) {
      ga4.event(
        "whoofus_screen_start_theme_selected",
        "Selected theme from Start page "
      );
    }
  };
  function toggleActiveStyles(index) {
    if (themes.themeList[index] === themes.activeCard) {
      return "tile active";
    } else {
      return "tile";
    }
  }

  useEffect(() => {
    if (ga4) {
      ga4.event(
        "whoofus_screen_start_new_game",
        "Start a new game screen loaded"
      );
    }
  }, []);

  function handleTextInput(event) {
    event.preventDefault();
    let playerName = event.target.value;
    setPlayerName(playerName);

    if (playerName && playerName !== "") {
      setValidationResult(null);
      setValidationResultContainerHidden(true);
    }

    if (validateNameLength(playerName)) {
      setValidationResult(null);
      setValidationResultContainerHidden(true);
    }
  }

  return (
    <div className="sp-container">
      <Logo />
      <div className="sp-input-container">
        <NameLabel />
        <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-text-box-container">
          <input
            className={
              !validationResult
                ? "sp-text-box"
                : "sp-text-box sp-text-box-validated"
            }
            type="text"
            name="tbName"
            value={playerName}
            onChange={handleTextInput}
          />
        </div>
        <ValidationError
          description={validationResult}
          isHidden={isValidationResultContainerHidden}
        />
      </div>
      {isChristmasThemeActive() ? (
        <div className="sp-input-container">
          <TileLabel />
          <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 cards">
            {themes.themeList.map((theme, index) => (
              <div
                className={toggleActiveStyles(index)}
                key={index}
                onClick={() => {
                  toggleActive(index);
                }}
              >
                {theme.imgUrl === wickedOrInnocent ? (
                  <img
                    src={theme.imgUrl}
                    alt="Wicked or Innocent"
                    className="wickImg"
                  />
                ) : (
                  <img src={theme.imgUrl} alt="Naughty or Nice" />
                )}

                <div className="tilesTitle">
                  {theme.theme_id === "wicked-innocent" ? (
                    <span>
                      WICKED OR <br />
                      INNOCENT?
                    </span>
                  ) : (
                    <span>
                      NAUGHTY <br />
                      OR NICE?
                    </span>
                  )}
                </div>
              </div>
            ))}
          </div>
          <CardValidationError
            description={cardValidationResult}
            isHidden={isCardValidationResultContainerHidden}
          />
        </div>
      ) : null}

      <StartButton
        setValidationResult={setValidationResult}
        setCardValidationResult={setCardValidationResult}
        playerName={playerName}
        selectedThemes={themes.activeCard || ""}
        setValidationResultContainerHidden={setValidationResultContainerHidden}
        setCardValidationResultContainerHidden={
          setCardValidationResultContainerHidden
        }
        christmasThemeDisable={isChristmasThemeActive()}
        ga4={ga4}
      />
    </div>
  );
}

function StartButton({
  setValidationResult,
  setCardValidationResult,
  setCardValidationResultContainerHidden,
  setValidationResultContainerHidden,
  ga4,
  selectedThemes = "",
  christmasThemeDisable = "",
  playerName,
}) {
  let history = useHistory();
  let auth = useAuth();

  function handleClick(e) {
    e.preventDefault();
    //genericTap.play();

    // FIXME: This validation logic could probably be merged with the button disable logic. In that case, however,
    // no cues will be available for the users to understand why (i.e. a number at the front) the provided name
    // wasn't accepted as a valid name.
    if (!playerName || playerName === "") {
      setValidationResult("* Nope. Your name can't be blank.");
      setValidationResultContainerHidden(false);
      return;
    } else if (!validateNameLength(playerName)) {
      setValidationResult(
        "* Sorry, your name needs to between 2 and 12 characters long."
      );
      setValidationResultContainerHidden(false);
      return;
    }
    if (christmasThemeDisable && selectedThemes === "") {
      setCardValidationResult("* Sorry, you have not selected any game.");
      setCardValidationResultContainerHidden(false);
      return;
    }

    if (ga4) {
      ga4.event(
        "whoofus_button_click_start_the_game",
        playerName + " started the game."
      );
    }

    sendMessage(
      {
        action: "start",
        user_id: playerName,
        theme_id: selectedThemes.theme_id,
        auth: {
          session_id: Session.get(SESSION_KEY_SESSION_ID),
        },
      },
      (res) => {
        if (res) {
          let data = JSON.parse(res.data);
          // auth.signin((userId) => {
          //     history.replace(from);
          // });
          if (data.error) {
            return alert(
              "An error occurred while starting a game group. This is our fault. " +
                "Please try again later."
            );
          }

          auth.signIn(
            data.group_id,
            data.user_id,
            data.session_id,
            data.is_group_leader,
            data.theme_id
          );

          history.push({
            pathname: "/waiting",
            state: {
              playerName: playerName,
              roomNumber: data.group_id,
              isGroupLeader: data.is_group_leader,
              theme_id: data.theme_id,
            },
          });
        }
      }
    );
  }

  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-button-wrapper">
      <button className="sp-button" type="button" onClick={handleClick}>
        LOCK IT IN
      </button>
      <ButtonShadow />
    </div>
  );
}

function NameLabel() {
  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-text-label">
      WHAT'S
      <br />
      YOUR NAME?
    </div>
  );
}
function TileLabel() {
  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-text-tile-label">
      WHAT’S YOUR
      <br />
      GAME?
    </div>
  );
}

function Logo() {
  return (
    <img
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 logo-img"
      src={logoImage}
      alt="Start Logo"
    />
  );
}

function ValidationError({ description, isHidden }) {
  return (
    <div
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-val-cont"
      hidden={isHidden}
    >
      {description}
    </div>
  );
}
function CardValidationError({ description, isHidden }) {
  return (
    <div
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-val-cont"
      hidden={isHidden}
    >
      {description}
    </div>
  );
}

export default StartPage;
