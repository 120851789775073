import React, {useState} from "react";
import {ReactSession as Session} from "react-client-session";
import "./../css/landing.css";
import {useGA4React} from "ga-4-react";
import {useHistory, useLocation} from "react-router-dom";
//import {genericTap} from "../modules/soundEffectsLoader";
import backgroundImg from "./../images/title.png";
import { CSSTransition } from "react-transition-group";

function LandingPage() {
    Session.setStoreType("localStorage");

    const [showHelpContent, setShowHelpContent] = useState(false);
    const [showInfoContent, setShowInfoContent] = useState(true);

    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    const [wineType] = useState(query.get("type") ? query.get("type") : "NOT_DEFINED");

    const ga4 = useGA4React();
    if (ga4) {
        ga4.event("whoofus_screen_landing", "WhoOfUs web application loaded");
        ga4.event("whoofus_wine_type", wineType);
    }

    const AgreeButton = () => {
        function handleClick() {
            // tap.play();
            if (ga4) {
                ga4.event('whoofus_button_click_over_eighteen', "Player is over eighteen");
            }
            setShowHelpContent(true);
        }

        return (
            <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 lp-btn-container">
                <button className="lp-button" type="button" onClick={handleClick}>
                    I'M OVER 18. LET ME PLAY!
                </button>
                <ButtonShadow/>
            </div>
        );
    };

    const BackgroundImage = () => {
        return (
            <img className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-10 col-xl-7 lp-bg-img" src={backgroundImg}
                 alt=""/>
        );
    };

    const WelcomeText = () => {
        return (
            <div className="col-10 col-sm-10 col-md-6 col-lg-8 col-xl-5 lp-title">Welcome</div>
        );
    };

    const InfoText = () => {
        return (
            <div className="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 info-div">Who Of Us is a game for friends to find out
                more about each other. Can you guess who among your friends is the most <span
                    className="info-span">wicked</span>, and who is the most <span
                    className="info-span">innocent</span>? <br/><br/> Warning: May contain 'adult themes', hilarity and some
                awkward truths!</div>
        );
    }

    return (
        <div className="grid-container">
            <BackgroundImage/>
            {
                showInfoContent && (
                    <WelcomeText/>
                )
            }
            <CSSTransition id="1" in={showHelpContent} timeout={1500} classNames="help-anim"
                           onEnter={() => setShowInfoContent(false)}
                           onExited={() => setShowInfoContent(true)} unmountOnExit>
                <HowToPlayText/>
            </CSSTransition>
            {
                showInfoContent && (
                    <InfoText/>
                )
            }
            <CSSTransition id="2" in={showHelpContent} timeout={1500} classNames="help-anim"
                           onEnter={() => setShowInfoContent(false)}
                           onExited={() => setShowInfoContent(true)} unmountOnExit>
                <StepsText/>
            </CSSTransition>
            {
                showInfoContent && (
                    <AgreeButton/>
                )
            }
            <CSSTransition id="3" in={showHelpContent} timeout={1500} classNames="help-anim"
                           onEnter={() => setShowInfoContent(false)}
                           onExited={() => setShowInfoContent(true)} unmountOnExit>
                <NextButton/>
            </CSSTransition>
        </div>
    );
}

const HowToPlayText = () => {
    return (
        <div className="col-10 col-sm-10 col-md-6 col-lg-8 col-xl-5 lp-title">How to play</div>
    );
};

function StepsText() {

    const StepTextRow = ({id, text}) => {
        return (
            <div>
                <div className="step-title">STEP {id}</div>
                <div className="step-text">{text}</div>
            </div>
        );
    };

    const StepTwo = () => {
        return (
            <div>
                <div className="step-title">STEP 2</div>
                <div className="step-text">One person ‘starts’ a game<br/>(everyone else can ‘join’)</div>
            </div>
        );
    }

    return (
        <div className="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 steps-container">
            <StepTextRow id="1" text="Grab 3-8 friends, devices in hand"/>
            <StepTwo/>
            {/*<StepTextRow id="2" text="One person ‘starts’ a game (everyone else can ‘join’)"/>*/}
            <StepTextRow id="3" text="Assume the brace position"/>
            <StepTextRow id="4" text="Play!"/>
        </div>
    );
}

function NextButton ({ga4, tap}) {
    let history = useHistory();

    function handleClick() {
        // tap.play();
        history.push("/welcome");
        if (ga4) {
            ga4.event('whoofus_button_click_next', "Player chose to proceed with the game");
        }
    }

    return (
        <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 lp-btn-container">
            <button className="lp-button" type="button"  onClick={handleClick}>
                NEXT
            </button>
            <ButtonShadow/>
        </div>
    );
}

function ButtonShadow({isHidden}) {
    return(
        <div className="lp-shadow-underlay" hidden={isHidden}/>
    );
}

export default LandingPage;