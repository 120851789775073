import './css/App.css';
import React from 'react';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import LandingPage from "./components/landing";
import StartPage from "./components/start";
import JoinPage from "./components/join";
import WaitingPage from "./components/waiting";
import QuestionPage from "./components/question";
import RevealPage from "./components/reveal";
import OutroPage from "./components/outro";
import {useAuth, ProvideAuth} from "./modules/authenticator";
import WelcomePage from "./components/welcome";

function App() {
    return (
        <ProvideAuth>
            <Router>
                <Switch>
                    <Route exact path="/">
                        <LandingPage/>
                    </Route>
                    <Route path="/welcome">
                        <WelcomePage/>
                    </Route>
                    <Route path="/start">
                        <StartPage/>
                    </Route>
                    <Route path="/join">
                        <JoinPage/>
                    </Route>
                    <PrivateRoute path="/waiting">
                        {/*<Route path="/waiting">*/}
                        <WaitingPage/>
                    </PrivateRoute>
                    <PrivateRoute path="/question">
                        {/*<Route path="/question">*/}
                        <QuestionPage/>
                    </PrivateRoute>
                    <PrivateRoute path="/reveal">
                        <RevealPage/>
                    </PrivateRoute>
                    {/*<Route path="/reveal">*/}
                    {/*    <RevealPage />*/}
                    {/*</Route>*/}
                    <PrivateRoute path="/outro">
                        <OutroPage/>
                    </PrivateRoute>
                    {/*<Route path="/outro">*/}
                    {/*    <OutroPage />*/}
                    {/*</Route>*/}
                </Switch>
            </Router>
        </ProvideAuth>
    );
}

function PrivateRoute({children, ...rest}) {
    let auth = useAuth();
    return (
        <Route
            {...rest}
            render={({location}) =>
                auth.sessionId ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/",
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

export default App;
