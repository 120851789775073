import WebSocket from 'isomorphic-ws';
import {ReactSession as Session} from "react-client-session";
import {SESSION_KEY_SESSION_ID, SESSION_KEY_GROUP_ID, SESSION_KEY_USER_ID} from "../constants";

const WSS_URL = process.env.REACT_APP_WSS_URL || "";
const KEEPALIVE_INTERVAL = 8 * 60 * 1000;

// Configure session store type.
Session.setStoreType("localStorage");

let wss;
let timeout;

export function getConnection(cbConnOpen, cbResult) {
    if (!wss || wss.readyState === WebSocket.CLOSING || wss.readyState === WebSocket.CLOSED) {
        wss = new WebSocket(WSS_URL);
        clearKeepaliveTimeout();
        handleKeepalive();
    }
    if (cbConnOpen) wss.onopen = cbConnOpen;
    if (cbResult) wss.onmessage = cbResult;

    wss.onerror = err => {
        console.error("Error occurred:", err);
    };
    return wss;
}

export function sendMessage(payload, cb) {
    let wss = getConnection();

    if (cb) wss.onmessage = res => {
        clearKeepaliveTimeout();
        handleKeepalive();
        cb(res);
    };

    let requestProcessed = false;
    if (wss.readyState === WebSocket.CONNECTING) {
        wss.onopen = () => {
            if (!requestProcessed) {
                requestProcessed = true;
                wss.send(JSON.stringify(payload));
                clearKeepaliveTimeout();
            }
        }
    } else if (wss.readyState === WebSocket.OPEN) {
        if (!requestProcessed) {
            requestProcessed = true;
            wss.send(JSON.stringify(payload));
            clearKeepaliveTimeout();
        }
    }
}

export function handleKeepalive() {
    timeout = setTimeout(() => {
        let origCb = wss.onmessage;
        sendMessage({
            action: "ping",
            group_id: Session.get(SESSION_KEY_GROUP_ID),
            user_id: Session.get(SESSION_KEY_USER_ID),
            auth: {
                session_id: Session.get(SESSION_KEY_SESSION_ID)
            }
        }, (res) => {
            if (res && res.data) {
                let data = JSON.parse(res.data);
                if (data.action !== "ping") {
                    origCb(res);
                }
            }
        },);
    }, KEEPALIVE_INTERVAL);
}

export function closeConnection() {
    clearKeepaliveTimeout();
    wss.close();
}

export function clearKeepaliveTimeout() {
    clearTimeout(timeout);
}

export function isConnectionValid(conn) {
    return conn.readyState !== WebSocket.CLOSING && conn.readyState !== WebSocket.CLOSED
}

export function isConnectionOpen(conn) {
    return conn.readyState === WebSocket.OPEN;
}