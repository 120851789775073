import React from "react";
import {ReactSession as Session} from "react-client-session";
import {useGA4React} from "ga-4-react";
import {useHistory, useLocation} from "react-router-dom";
import "./../css/welcome.css"
import titleImage from "./../images/title.png";
import logoImage from "./../images/logo.png";
import ButtonShadow from "./buttonShadow";
import {TERMS_AND_CONDITIONS_URL} from "../constants";

function WelcomePage() {
    Session.setStoreType("localStorage");

    let location = useLocation();
    let playAgain = location.state ? location.state.playAgain : false;
    const ga4 = useGA4React();

    if (ga4) {
        ga4.event("whoofus_screen_welcome", "Welcome screen loaded");
    }

    return (
        <div className="wp-container">
            <Logo/>
            <TitleImage/>
            <ButtonStack playAgain={playAgain}/>
            <TAndCLink hidden={playAgain}/>
        </div>
    );
}

function Logo() {
    return (
        <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 logo-wrapper">
            <img className="logo-img" src={logoImage} alt="Logo Image"/>
        </div>
    );
}

function TitleImage() {
    return (
        <img className="col-9 col-s-9 col-sm-9 col-md-9 col-lg-8 col-xl-5 col-xxl-5 title-img" src={titleImage} alt="Title Image"/>
    );
}

function ButtonStack({playAgain}) {
    const ga4 = useGA4React();

    if (playAgain) {
        return (
            <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 wp-stack">
                <div className="wp-button-wrapper">
                    <StartButton ga4={ga4}/>
                    <ButtonShadow/>
                </div>
                <div className="wp-button-wrapper">
                    <JoinButton ga={ga4}/>
                    <ButtonShadow/>
                </div>
                <div className="wp-button-wrapper">
                    <SubscriptionButton ga={ga4}/>
                    <ButtonShadow/>
                </div>
            </div>
        );
    } else {
        return (
            <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 col-xxl-5 wp-stack">
                <div className="wp-button-wrapper">
                    <StartButton ga4={ga4}/>
                    <ButtonShadow/>
                </div>
                <div className="wp-button-wrapper">
                    <JoinButton ga={ga4}/>
                    <ButtonShadow/>
                </div>
            </div>
        );
    }
}

function StartButton ({ga4, tap}) {
    let history = useHistory();

    function handleClick() {
        //tap.play();
        history.push('/start');
        if (ga4) {
            ga4.event('whoofus_button_click_start_new_game', "Start button clicked");
        }
    }

    return (
        <button className="wp-button start-button" type="button" onClick={handleClick}>START A NEW GAME</button>
    );
}

function JoinButton ({ga4, tap}) {
    let history = useHistory();

    function handleClick() {
        //tap.play();
        history.push('/join');
        if (ga4) {
            ga4.event('whoofus_button_click_join_game', "Join button clicked");
        }
    }

    return <button className="wp-button join-button" type="button" onClick={handleClick}>JOIN AN EXISTING GAME</button>;
}

function SubscriptionButton ({ga4, tap}) {
    function handleClick() {
        //tap.play();
        if (ga4) {
            ga4.event('whoofus_button_click_subscription', "Subscription button clicked");
        }
        window.open(
            "https://mailchi.mp/innocentbystander/whoofus",
            "_blank"
        );
    }
    return <button className="wp-button subscription-button" type="button" onClick={handleClick}>BECOME A BYSTANDER</button>;
}

function TAndCLink({hidden}) {
    return (
        <a className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 wp-tnc-link" href={TERMS_AND_CONDITIONS_URL}
           target="_blank" rel="noreferrer" hidden={hidden}>Terms & conditions</a>
    );
}

export default WelcomePage