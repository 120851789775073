import React, {createContext, useContext, useState} from "react";
import {ReactSession as Session} from "react-client-session";
import {
    SESSION_KEY_GROUP_ID,
    SESSION_KEY_IS_GROUP_LEADER,
    SESSION_KEY_OUTRO_STATE,
    SESSION_KEY_PRELOAD_ENABLED,
    SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION, SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL,
    SESSION_KEY_SESSION_ID,
    SESSION_KEY_USER_ID
} from "../constants";

function useProvideAuth() {
    Session.setStoreType("localStorage");
    const [user, setUser] = useState(null);
    const [sessionId, setSessionId] = useState(Session.get(SESSION_KEY_SESSION_ID));
    const [groupId, setGroupId] = useState(Session.get(SESSION_KEY_GROUP_ID));

    // const signin = cb => {
    //     return fakeAuth.signin(() => {
    //         setUser("user");
    //         cb();
    //     });
    // };

    const signIn = (groupId, userId, sessionId, isGroupLeader) => {
        Session.remove(SESSION_KEY_SESSION_ID);
        Session.remove(SESSION_KEY_GROUP_ID);
        Session.remove(SESSION_KEY_USER_ID);
        Session.remove(SESSION_KEY_IS_GROUP_LEADER);
        Session.remove(SESSION_KEY_OUTRO_STATE);
        Session.remove(SESSION_KEY_PRELOAD_ENABLED);
        Session.remove(SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION);
        Session.remove(SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL);

        setUser(userId);
        Session.set(SESSION_KEY_USER_ID, userId);
        setGroupId(groupId);
        Session.set(SESSION_KEY_GROUP_ID, groupId);
        setSessionId(sessionId);
        Session.set(SESSION_KEY_SESSION_ID, sessionId);
        Session.set(SESSION_KEY_IS_GROUP_LEADER, isGroupLeader);
    };

    const signOut = cb => {
        Session.remove(SESSION_KEY_SESSION_ID);
        Session.remove(SESSION_KEY_GROUP_ID);
        Session.remove(SESSION_KEY_USER_ID);
        Session.remove(SESSION_KEY_IS_GROUP_LEADER);
        Session.remove(SESSION_KEY_OUTRO_STATE);
        Session.remove(SESSION_KEY_PRELOAD_ENABLED);
        Session.remove(SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION);
        Session.remove(SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL);

        setUser(null);
        setGroupId(null);
        setSessionId(null);
    };

    return {
        user,
        sessionId,
        groupId,
        signIn,
        signOut
    };
}

// const fakeAuth = {
//     isAuthenticated: false,
//     signin(cb) {
//         fakeAuth.isAuthenticated = true;
//         setTimeout(cb, 100); // fake async
//     },
//     signout(cb) {
//         fakeAuth.isAuthenticated = false;
//         setTimeout(cb, 100);
//     }
// };

const authContext = createContext();

function ProvideAuth({children}) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

function useAuth() {
    return useContext(authContext);
}

export {useAuth, useProvideAuth, ProvideAuth};