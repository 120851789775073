import "../css/join.css";
import "../css/start.css";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { sendMessage } from "../modules/webSocketService";
import { useAuth } from "../modules/authenticator";
import { ReactSession as Session } from "react-client-session";
import { useGA4React } from "ga-4-react";
import { validateNameLength, validateRoomNumber } from "../util/util";
import logoImage from "../images/logo.png";
import ButtonShadow from "./buttonShadow";
import { SESSION_KEY_SESSION_ID } from "../constants";
//import {genericTap} from "../modules/soundEffectsLoader";

function JoinPage() {
  // Configures session store type.
  Session.setStoreType("localStorage");

  // let location = useLocation();
  let query = useQuery();
  const ga4 = useGA4React();
  const [playerName, setPlayerName] = useState("");
  const [roomNumber, setRoomNumber] = useState(
    query.get("roomNumber") ? query.get("roomNumber") : null
  );
  const [
    isNameValidationResultContainerHidden,
    setNameValidationResultContainerHidden,
  ] = useState(true);
  const [nameValidationResult, setNameValidationResult] = useState("");
  const [
    isRoomValidationResultContainerHidden,
    setRoomValidationResultContainerHidden,
  ] = useState(true);
  const [roomValidationResult, setRoomValidationResult] = useState("");

  useEffect(() => {
    if (ga4) {
      ga4.event("whoofus_screen_join_game", "Join the game screen loaded");
    }
  }, []);

  useEffect(() => {}, [nameValidationResult, roomValidationResult]);

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  function handlePlayerNameInput(event) {
    event.preventDefault();
    setPlayerName(event.target.value);

    if (playerName && playerName !== "") {
      setNameValidationResult(null);
      setNameValidationResultContainerHidden(true);
    }

    if (validateNameLength(playerName)) {
      setNameValidationResult(null);
      setNameValidationResultContainerHidden(true);
    }
  }

  function handleRoomNumberInput(event) {
    event.preventDefault();
    setRoomNumber(event.target.value);

    if (validateRoomNumber(roomNumber)) {
      setRoomValidationResult(null);
      setRoomValidationResultContainerHidden(true);
    }
  }

  return (
    <div className="jp-container">
      <Logo />
      <div className="jp-input-container">
        <NameLabel />
        <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-text-box-container">
          <input
            className={
              !nameValidationResult
                ? "sp-text-box"
                : "sp-text-box sp-text-box-validated"
            }
            type="text"
            name="tbName"
            value={playerName}
            onChange={handlePlayerNameInput}
          />
        </div>
        <ValidationError
          description={nameValidationResult}
          isHidden={isNameValidationResultContainerHidden}
        />
      </div>
      <div className="jp-input-container">
        <RoomLabel />
        <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-text-box-container">
          <div className="jp-text-div">
            Enter the room number to join a game your friend has started.
          </div>
          <input
            className={
              !roomValidationResult
                ? "sp-text-box"
                : "sp-text-box sp-text-box-validated"
            }
            type="text"
            name="tbRoomNumber"
            value={roomNumber !== null ? roomNumber : ""}
            onChange={handleRoomNumberInput}
          />
        </div>
        <ValidationError
          description={roomValidationResult}
          isHidden={isRoomValidationResultContainerHidden}
        />
      </div>
      <JoinButton
        playerName={playerName}
        roomNumber={roomNumber}
        setNameValidationResult={setNameValidationResult}
        setNameValidationResultContainerHidden={
          setNameValidationResultContainerHidden
        }
        setRoomValidationResult={setRoomValidationResult}
        setRoomValidationResultContainerHidden={
          setRoomValidationResultContainerHidden
        }
      />
    </div>
  );
}

function NameLabel() {
  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-text-label">
      WHAT'S
      <br />
      YOUR NAME?
    </div>
  );
}

function RoomLabel() {
  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 sp-text-label">
      ROOM#
    </div>
  );
}

function JoinButton({
  setNameValidationResult,
  setNameValidationResultContainerHidden,
  setRoomValidationResult,
  setRoomValidationResultContainerHidden,
  playerName,
  roomNumber,
}) {
  let history = useHistory();
  let auth = useAuth();

  function handleClick(event) {
    event.preventDefault();
    // genericTap.play();

    //FIXME: Add Google Analytics here.

    let areAllInputsValid = true;
    if (!playerName || playerName === "") {
      setNameValidationResult("* You need to enter your name to start a game.");
      setNameValidationResultContainerHidden(false);
      areAllInputsValid = false;
    } else if (!validateNameLength(playerName)) {
      setNameValidationResult(
        "* Sorry, your name needs to between 2 and 12 characters long."
      );
      setNameValidationResultContainerHidden(false);
      areAllInputsValid = false;
    }

    if (!validateRoomNumber(roomNumber)) {
      setRoomValidationResult(
        "* Please enter a valid room number. Ask the person who started the game."
      );
      setRoomValidationResultContainerHidden(false);
      areAllInputsValid = false;
    }

    if (!areAllInputsValid) return;

    sendMessage(
      {
        action: "join",
        group_id: roomNumber,
        user_id: playerName,
        auth: {
          session_id: Session.get(SESSION_KEY_SESSION_ID),
        },
      },
      (res) => {
        if (res) {
          let data = JSON.parse(res.data);
          if (data.error) {
            let error = data.error;
            switch (error.code) {
              case 10000:
                setNameValidationResult(
                  "* This name is already in use, pick a name just for " +
                    "you that your friends will recognise."
                );
                setNameValidationResultContainerHidden(false);
                break;
              case 10002:
                setRoomValidationResult(
                  "* This room number is incorrect. Check with the person " +
                    "who started the game."
                );
                setRoomValidationResultContainerHidden(false);
                break;
              case 10003:
                setRoomValidationResult(
                  "* This game has already started! Ask your friend to start again, or wait for the next round :)"
                );
                setRoomValidationResultContainerHidden(false);
                break;
              default:
              // do nothing.
            }
            return;
          }

          auth.signIn(
            data.group_id,
            data.user_id,
            data.session_id,
            data.is_group_leader
          );

          history.push({
            pathname: "/waiting",
            state: {
              playerName: data.user_id,
              roomNumber: data.group_id,
              isGroupLeader: data.is_group_leader,
              theme_id: data.theme_id,
            },
          });
        }
      }
    );
  }

  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 jp-button-wrapper">
      <button className="sp-button" type="button" onClick={handleClick}>
        LOCK IT IN
      </button>
      <ButtonShadow />
    </div>
  );
}

function Logo() {
  return (
    <img
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 logo-img"
      src={logoImage}
      alt="Logo Image"
    />
  );
}

function ValidationError({ description, isHidden }) {
  return (
    <div
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 jp-val-cont"
      hidden={isHidden}
    >
      {description}
    </div>
  );
}

export default JoinPage;
