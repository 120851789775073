import "../css/outro.css";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import {
  closeConnection,
  getConnection,
  isConnectionOpen,
} from "../modules/webSocketService";
import { ReactSession as Session } from "react-client-session";
import { useGA4React } from "ga-4-react";
import WebSocket from "isomorphic-ws";
import ButtonShadow from "./buttonShadow";
import {
  SEND_FEEDBACK_FORM_URL,
  SESSION_KEY_GROUP_ID,
  SESSION_KEY_IS_GROUP_LEADER,
  SESSION_KEY_OUTRO_STATE,
  SESSION_KEY_PRELOAD_ENABLED,
  SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION,
  SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL,
  SESSION_KEY_SESSION_ID,
  SESSION_KEY_USER_ID,
} from "../constants";
//import {winnersClip} from "../modules/soundEffectsLoader";
import outroImg from "../images/outo_img_black_leaf.png";
import greenLeaf from "../images/img.divider.xmas.grn.png";
import redLeaf from "../images/img.divider.xmas.red.png";
import redShadowMan from "../images/Shadowman_red.png";
import naughtyOrNice from "../images/shadowman_naughty_nice.png";
import wickedOrInnocent from "../images/Shadowman_pink.png";
import innocentByLogo from "../images/img_logo_lockup_blk.png";

const PHRASES = [
  "interesting!",
  "insightful!",
  "illuminating!",
  "educational!",
  "informative!",
  "revealing...",
  "stimulating...",
  "thought-provoking...",
];
const phraseId = Math.floor(Math.random() * (PHRASES.length - 1));
const phrase = "Well, that was " + PHRASES[phraseId];

const playAgainOptions = [
  "DISAGREE? PLAY AGAIN!",
  "DON'T BELIEVE US? PLAY AGAIN!",
  "BEG TO DIFFER? PLAY AGAIN!",
  "WANT TO MAKE SURE? PLAY AGAIN!",
  "I DEMAND A RECOUNT!",
  "LOL! ONE MORE TIME!",
  "NEED A SECOND OPINION?",
  "SAY IT AINT SO?",
];
const playAgainOptionId = Math.floor(
  Math.random() * (playAgainOptions.length - 1)
);
const playAgainPhrase = playAgainOptions[playAgainOptionId];

const ENABLE_PLAY_AGAIN_BUTTON_TIMEOUT_IN_MILLIS = 2500;
// const PRELOAD_TIMEOUT_IN_SECONDS = 4;

const STATE = {
  PRELOAD_STARTED: "preloadStarted",
  PRELOAD_TEXT_DISPLAYED: "preloadTextDisplayed",
  PRELOAD_INFO_TEXT_DISPLAYED: "preloadInfoTextDisplayed",
  PRELOAD_ENDED: "preloadEnded",
  RESULTS_DISPLAYED: "resultDisplayed",
  WICKED_CONTAINER_STYLES_ADDED: "wickedContainerStylesAdded",
  PLAY_AGAIN_BUTTON_APPEARED: "playAgainButtonAppeared",
};

function OutroPage() {
  // Configures session store type.
  Session.setStoreType("localStorage");

  let location = useLocation();
  let history = useHistory();
  const ga4 = useGA4React();

  const [questionType] = useState(
    location.state && location.state.questionType
      ? location.state.questionType
      : ""
  );
  let ouThemeId = getThemeId(location);

  const [groupId] = useState(getGroupId(location));
  const [currentPlayer] = useState(getCurrentPlayer(location));
  const [mostInnocent, setMostInnocent] = useState("");
  const [mostWicked, setMostWicked] = useState("");

  const [nicest, setNicest] = useState("");
  const [naughtiest, setNaughtiest] = useState("");
  const [isPreloadEnabled, setPreloadEnabled] = useState(
    getPreloadEnabled(location)
  );
  const [outroState, setOutroState] = useState(STATE.PRELOAD_STARTED);
  const [isPlayAgainButtonHidden, setPlayAgainButtonHidden] = useState(true);
  const [isPreloadTextHidden, setPreloadTextHidden] = useState(true);
  const [isPreloadInfoTextHidden, setPreloadInfoTextHidden] = useState(true);
  const [wickedContainerClass, setWickedContainerClass] = useState(
    "ouw-container-before"
  );
  const [naughtyContainerClass, setNaughtyContainerClass] = useState(
    "ouNaughty-container-before"
  );
  const [isDashedLineLoaded, setDashedLineLoaded] = useState(false);
  const [wss, setWss] = useState(getConnection());

  function getPreloadEnabled(location) {
    if (location.state && location.state.isPreloadEnabled) {
      return location.state.isPreloadEnabled;
    }
    return false;
  }

  function getGroupId(location) {
    if (location.state && location.state.groupId) {
      return location.state.groupId;
    }
    return Session.get(SESSION_KEY_GROUP_ID) || "";
  }
  function getThemeId(location) {
    if (
      location.state &&
      (location.state.questionType === "innocent" ||
        location.state.questionType === "wicked")
    ) {
      return (location.state.theme_id = "wicked-innocent");
    }
    if (
      location.state &&
      (location.state.questionType === "nice" ||
        location.state.questionType === "naughty")
    ) {
      return (location.state.theme_id = "naughty-nice");
    }
  }

  function getCurrentPlayer(location) {
    if (location.state && location.state.currentPlayer) {
      return location.state.currentPlayer;
    }
    return Session.get(SESSION_KEY_USER_ID) || "";
  }

  useEffect(() => {
    window.onpopstate = function (event) {
      history.go(1);
    };
  }, []);

  useEffect(() => {
    if (ga4) {
      if (history.location.state.theme_id == "naughty-nice" ) {
        ga4.event(
            "whoofus_screen_outro_christmas_theme",
            "Outro screen loaded for the player " + currentPlayer
        );
      } else {
        ga4.event(
            "whoofus_screen_outro",
            "Outro screen loaded for the player " + currentPlayer
        );
      }
    }
    if (!isConnectionOpen(wss)) {
      setWss(getConnection());
      registerResultHandler(wss);
    }
    switch (wss.readyState) {
      case WebSocket.OPEN:
        wss.send(
          JSON.stringify({
            action: "validate_session",
            group_id: groupId,
            user_id: currentPlayer,
            auth: {
              session_id: Session.get(SESSION_KEY_SESSION_ID),
            },
          })
        );
        break;
      case WebSocket.CONNECTING:
        wss.onopen = () => {
          wss.send(
            JSON.stringify({
              action: "validate_session",
              group_id: groupId,
              user_id: currentPlayer,
              auth: {
                session_id: Session.get(SESSION_KEY_SESSION_ID),
              },
            })
          );
        };
        break;
      case WebSocket.CONNREFUSED:
        alert(
          "Failed to detect a valid network connection. Check your internet connectivity, " +
            "and try again."
        );
    }
  }, []);

  useEffect(() => {
    if (outroState === STATE.PRELOAD_STARTED) {
      setTimeout(() => {
        setPreloadTextHidden(false);
        setOutroState(STATE.PRELOAD_TEXT_DISPLAYED);
      }, 200);
    } else if (outroState === STATE.PRELOAD_TEXT_DISPLAYED) {
      setTimeout(() => {
        setPreloadInfoTextHidden(false);
        setOutroState(STATE.PRELOAD_INFO_TEXT_DISPLAYED);
      }, 1500);
    } else if (outroState === STATE.PRELOAD_INFO_TEXT_DISPLAYED) {
      setTimeout(() => {
        setPreloadEnabled(false);
        setOutroState(STATE.PRELOAD_ENDED);
      }, 3000);
    } else if (outroState === STATE.PRELOAD_ENDED) {
      setTimeout(() => {
        setWickedContainerClass("ouw-container-before");
        setOutroState(STATE.WICKED_CONTAINER_STYLES_ADDED);
      }, 1000);
    } else if (outroState === STATE.PRELOAD_ENDED) {
      setTimeout(() => {
        setNaughtyContainerClass("ouw-container-before");
        setOutroState(STATE.WICKED_CONTAINER_STYLES_ADDED);
      }, 1000);
    } else if (outroState === STATE.WICKED_CONTAINER_STYLES_ADDED) {
      setTimeout(() => {
        setPlayAgainButtonHidden(false);
        setOutroState(STATE.PLAY_AGAIN_BUTTON_APPEARED);
      }, ENABLE_PLAY_AGAIN_BUTTON_TIMEOUT_IN_MILLIS);
    }
    Session.set(SESSION_KEY_OUTRO_STATE, outroState);
  }, [outroState]);

  useEffect(() => {
    registerResultHandler(wss);
  }, [wss]);

  function registerResultHandler(wss) {
    wss.onmessage = (res) => {
      if (res) {
        const data = JSON.parse(res.data);

        if (data.message === "Internal server error") {
          return alert(
            "An error has occurred. This is our fault. Please start over."
          );
        }
        switch (data.action) {
          case "get_all_results":
            const mostInnocent = data.mostInnocent;
            setMostInnocent(mostInnocent);
            const mostWicked = data.mostWicked;
            setMostWicked(mostWicked);
            const nicest = data.nicest;
            setNicest(nicest);
            const naughtiest = data.naughtiest;
            setNaughtiest(naughtiest);

            closeConnection();
            break;
          case "validate_session":
            if (data.status === false) {
              closeConnection();
              history.push({
                pathname: "/welcome",
                state: {},
              });
              return;
            }
            wss.send(
              JSON.stringify({
                action: "get_all_results",
                group_id: groupId,
                user_id: currentPlayer,
                theme_id: ouThemeId,
                auth: {
                  session_id: Session.get(SESSION_KEY_SESSION_ID),
                },
              })
            );
            break;
          default:
          // Do nothing.
        }
      }
    };
  }

  if (isPreloadEnabled) {
    Session.set(SESSION_KEY_PRELOAD_ENABLED, false);
    return (
      <div className="ou-preload-container">
        <PreloadText isHidden={isPreloadTextHidden} />
        <InfoText
          isHidden={isPreloadInfoTextHidden}
          questionType={questionType}
        />
      </div>
    );
  } else {
    return (
      <div
        className={
          questionType === "innocent" || questionType === "wicked"
            ? "ou-container"
            : "ou-xmas-container"
        }
      >
        <DashedLine
          isHidden={isDashedLineLoaded}
          setDashedLineLoaded={setDashedLineLoaded}
        />

        {questionType === "innocent" || questionType === "wicked" ? (
          <>
            <InnocentDataContainer
              winner={mostInnocent}
              isHidden={!isDashedLineLoaded}
            />
            <PlayAgainButton
              isHidden={isPlayAgainButtonHidden}
              ga4={ga4}
              currentPlayer={currentPlayer}
              questionType={questionType}
            />
            <WickedDataContainer
              winner={mostWicked}
              className={wickedContainerClass}
              isHidden={!isDashedLineLoaded}
              questionType={questionType}
            />
            <SendFeedbackLink isHidden={isPlayAgainButtonHidden} />
          </>
        ) : (
          <>
            <NiceDataContainer winner={nicest} isHidden={!isDashedLineLoaded} />
            <div className="red-shadowman-center">
              <img
                src={redShadowMan}
                className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 red-shawow-img"
                alt="Shadowman"
              />
            </div>
            <NaughtyDataContainer
              winner={naughtiest}
              className={naughtyContainerClass}
              isHidden={!isDashedLineLoaded}
              questionType={questionType}
              ga4={ga4}
              currentPlayer={currentPlayer}
            />
          </>
        )}
      </div>
    );
  }
}

function DashedLine({ isHidden, setDashedLineLoaded }) {
  function handleAnimationEnd(e) {
    e.preventDefault();
    setDashedLineLoaded(true);
  }

  return (
    <div
      className="line-cont"
      hidden={isHidden}
      onAnimationEnd={handleAnimationEnd}
    />
  );
}

function PreloadText({ isHidden }) {
  return (
    <div
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 ou-preload-div"
      hidden={isHidden}
    >
      {phrase}
    </div>
  );
}

function InfoText({ isHidden, questionType }) {
  return (
    <div
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 outro-preload-info-div"
      hidden={isHidden}
    >
      Get ready to find out who among you is the most{" "}
      {questionType === "nice" || questionType === "naughty" ? (
        <>
          <span className="info-naughty">naughty</span>
          <span> and </span>
          <span className="info-nice">nice.</span>
          <div className="outo-bottom-div">
            <img src={outroImg} className="bottom-img" />
          </div>
        </>
      ) : (
        <>
          <span className="info-span">wicked</span> and
          <span className="info-span">innocent.</span>
        </>
      )}
    </div>
  );
}

function InnocentDataContainer({ winner, isHidden }) {
  return (
    <div
      className="col-8 col-s-8 col-sm-8 col-md-8 col-lg-8 ou-inner-container oui-container"
      hidden={isHidden}
    >
      <div className="oui-result-container">{winner.toUpperCase()}</div>
      <div className="oui-result-desc-container">is innocent.</div>
    </div>
  );
}

function WickedDataContainer({ winner, className, isHidden }) {
  return (
    <div
      className={
        "col-8 col-s-8 col-sm-8 col-md-8 col-lg-8 ou-inner-container " +
        className
      }
      hidden={isHidden}
    >
      <div className="ouw-result-container">{winner.toUpperCase()}</div>
      <div className="ouw-result-desc-container">is wicked.</div>
    </div>
  );
}

function NiceDataContainer({ winner, isHidden }) {
  return (
    <div
      className="col-8 col-s-8 col-sm-8 col-md-8 col-lg-8 onice-inner-container ounice-container"
      hidden={isHidden}
    >
      <div className="ounice-result-container">{winner.toUpperCase()}</div>
      <div className="oui-result-desc-container">
        is <span className="info-nice">nice.</span>
      </div>

      <div className="outo-bottom-div">
        <img src={greenLeaf} className="green-leaf-img" />
      </div>
    </div>
  );
}
function NaughtyDataContainer({
  winner,
  className,
  questionType,
  isPlayAgainButtonHidden,
  isHidden,
  ga4,
  currentPlayer,
}) {
  let themes = [
    {
      id: 1,
      theme_id: "wicked-innocent",
      imgUrl: wickedOrInnocent,
    },
    {
      id: 2,
      theme_id: "naughty-nice",
      imgUrl: naughtyOrNice,
    },
  ];

  return (
    <div
      className={
        "col-8 col-s-8 col-sm-8 col-md-8 col-lg-8 onaughty-inner-container " +
        className
      }
      hidden={isHidden}
    >
      <div className="ounaughty-result-container">{winner.toUpperCase()}</div>
      <div className="ouw-result-desc-container">
        is <span className="info-naughty">naughty.</span>
      </div>
      <div className="outo-bottom-div">
        <img src={redLeaf} className="green-leaf-img" />
      </div>
      <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 rp-text-tile-label">
        ONE MORE ROUND?
      </div>

      <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 cards">
        {themes.map((theme, index) => (
          <div className="ou-tile" key={index}>
            {theme.imgUrl === wickedOrInnocent ? (
              <img
                src={theme.imgUrl}
                alt="Wicked or Innocent"
                className="wickImg"
              />
            ) : (
              <img src={theme.imgUrl} alt="Naughty or Nice" />
            )}

            <div className="ou-tilesTitle">
              {theme.theme_id === "wicked-innocent" ? (
                <p>
                  WICKED OR <br />
                  INNOCENT?
                </p>
              ) : (
                <p>
                  NAUGHTY <br />
                  OR NICE?
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
      <XmasPlayAgainButton
        isHidden={isPlayAgainButtonHidden}
        ga4={ga4}
        currentPlayer={currentPlayer}
        questionType={questionType}
      />
      <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 xmas-link">
        {/* <button
          className="know-more-btn"
          type="button"
          id="findOutMore"
          onclick="window.open('https://www.innocentbystander.com.au/', '_blank'); return false;"
        >
          MORE ABOUT INNOCENT BYSTANDARD
        </button> */}
        <a
          href="https://www.innocentbystander.com.au/"
          target="_blank"
          className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 find-out-link"
        >
          <img
            src={innocentByLogo}
            alt="Find out more about Innocent Bystandard"
            className="find-out-logo"
          />
          Find out more
        </a>
      </div>
      <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 xmas-sendLink">
        <SendFeedbackLink isHidden={isPlayAgainButtonHidden} />
      </div>
    </div>
  );
}

function PlayAgainButton({ ga4, currentPlayer, isHidden }) {
  let history = useHistory();

  function handleClick(event) {
    event.preventDefault();

    if (ga4) {
      ga4.event(
        "whoofus_button_click_play_again",
        currentPlayer + "play again button clicked"
      );
    }

    Session.remove(SESSION_KEY_SESSION_ID);
    Session.remove(SESSION_KEY_GROUP_ID);
    Session.remove(SESSION_KEY_USER_ID);
    Session.remove(SESSION_KEY_IS_GROUP_LEADER);
    Session.remove(SESSION_KEY_OUTRO_STATE);
    Session.remove(SESSION_KEY_PRELOAD_ENABLED);
    Session.remove(SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION);
    Session.remove(SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL);

    closeConnection();

    history.push({
      pathname: "/welcome",
      state: {
        playAgain: true,
      },
    });
  }

  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 ou-button-container">
      <button
        className="ou-play-button"
        type="button"
        id="btnPlayAgain"
        hidden={isHidden}
        onClick={handleClick}
      >
        {playAgainPhrase}
      </button>
      <ButtonShadow isHidden={isHidden} />
    </div>
  );
}
function XmasPlayAgainButton({ ga4, currentPlayer, isHidden }) {
  let history = useHistory();

  function xmasHandleClick(event) {
    event.preventDefault();

    if (ga4) {
      ga4.event(
        "whoofus_button_click_for_christmas_theme_play_again",
        currentPlayer + "play again button clicked from Christmas theme"
      );
    }

    Session.remove(SESSION_KEY_SESSION_ID);
    Session.remove(SESSION_KEY_GROUP_ID);
    Session.remove(SESSION_KEY_USER_ID);
    Session.remove(SESSION_KEY_IS_GROUP_LEADER);
    Session.remove(SESSION_KEY_OUTRO_STATE);
    Session.remove(SESSION_KEY_PRELOAD_ENABLED);
    Session.remove(SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION);
    Session.remove(SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL);

    closeConnection();

    history.push({
      pathname: "/welcome",
      state: {
        playAgain: true,
      },
    });
  }

  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 ouxmas-button-container">
      <button
        className="ouxmas-play-button"
        type="button"
        id="btnXmasPlayAgain"
        hidden={isHidden}
        onClick={xmasHandleClick}
      >
        {playAgainPhrase}
      </button>
      <ButtonShadow isHidden={isHidden} />
    </div>
  );
}

function SendFeedbackLink({ isHidden }) {
  return (
    <a
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 ou-link"
      href={SEND_FEEDBACK_FORM_URL}
      target="_blank"
      rel="noreferrer"
      hidden={isHidden}
    >
      Room for improvement?
    </a>
  );
}

export default withRouter(OutroPage);
