import React, { useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import {
  closeConnection,
  getConnection,
  isConnectionOpen,
} from "../modules/webSocketService";
import { ReactSession as Session } from "react-client-session";
import { useGA4React } from "ga-4-react";
import "./../css/reveal.css";
import timerW10s from "../resources/white_empty_10s";
import timerB10s from "../resources/black_empty_10s";

import timerNNW10s from "../resources/Naughty_Nice/xmas_white_empty_10s";
import timerNNB10s from "../resources/Naughty_Nice/xmas_black_empty_10s";

import lottie from "lottie-web";
import WebSocket from "isomorphic-ws";
import {
  SESSION_KEY_GROUP_ID,
  SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL,
  SESSION_KEY_SESSION_ID,
  SESSION_KEY_USER_ID,
} from "../constants";
//import {revealClip} from "../modules/soundEffectsLoader";
import blackLeftLeaf from "../images/black_left_leaf.png";
import blackRightLeaf from "../images/black_right_leaf.png";
import whiteLeftLeaf from "../images/white_left_leaf.png";
import whiteRightLeaf from "../images/white_right_leaf.png";
import outroImg from "../images/outo_img_black_leaf.png";

const REVEAL_TIMEOUT_IN_SECONDS = 10;
const TOTAL_NUMBER_OF_QUESTIONS = 12;

const STATE = {
  NO_ANSWER_PARA1_LOADING_STARTED: "noAnswerPara1LoadingStarted",
  NO_ANSWER_PARA1_LOADED: "noAnswerPara1Loaded",
  NO_ANSWER_PARA2_LOADED: "noAnswerPara2Loaded",
  NO_ANSWER_NN_PARA3_LOADED: "naughtyNiceParaLoaded",
};

export const SESSION_KEY_REVEAL_STATE = "revealState";

function RevealPage() {
  // Configure session store type.
  Session.setStoreType("localStorage");

  const history = useHistory();
  const location = useLocation();

  const [groupId] = useState(getRoomNumber(location));
  const [currentPlayer] = useState(getCurrentPlayer(location));
  const [currentQuestion] = useState(
    location.state && location.state.currentQuestion
      ? location.state.currentQuestion
      : ""
  );
  const [questionType] = useState(
    location.state && location.state.questionType
      ? location.state.questionType
      : ""
  );
  const [currentQuestionId] = useState(
    location.state && location.state.currentQuestionId
      ? location.state.currentQuestionId
      : ""
  );
  const [result] = useState(
    location.state && location.state.result ? location.state.result : ""
  );
  const [correctAnswerPercentage] = useState(
    location.state && location.state.correctAnswerPercentage
      ? location.state.correctAnswerPercentage
      : 0
  );
  const remainingSeconds = Session.get(
    SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL
  );
  const [seconds, setSeconds] = useState(
    remainingSeconds ? remainingSeconds : REVEAL_TIMEOUT_IN_SECONDS
  );
  const [timer, setTimer] = useState("");
  const [isNoAnswerPara1Hidden, setNoAnswerPara1Hidden] = useState(true);
  const [isNoAnswerPara2Hidden, setNoAnswerPara2Hidden] = useState(true);
  const [isNoAnswerImageHidden, setIsNoAnswerImageHidden] = useState(true);
  const ga4 = useGA4React();
  const incorrectAnswerPercentage = 100 - correctAnswerPercentage;
  const [numberOfCorrectAnswers] = useState(
    location.state && location.state.numCorrectAnswers
      ? location.state.numCorrectAnswers
      : 0
  );
  const [numberOfAnswers] = useState(
    location.state && location.state.numAnswers ? location.state.numAnswers : 0
  );
  const [allUsersAnswered] = useState(
    location.state && location.state.allUsersAnswered
      ? location.state.allUsersAnswered
      : false
  );

  const sessionStoredRevealState = Session.get(SESSION_KEY_REVEAL_STATE);
  const [revealState, setRevealState] = useState(
    sessionStoredRevealState
      ? sessionStoredRevealState
      : STATE.NO_ANSWER_PARA1_LOADING_STARTED
  );
  const [wss, setWss] = useState(getConnection());

  useEffect(() => {
    if (ga4) {
      ga4.event(
        "whoofus_screen_reveal",
        " Reveal screen loaded, for the question: '" +
          currentQuestion +
          "' for player " +
          currentPlayer
      );
    }
    window.onpopstate = function (event) {
      history.go(1);
    };
  }, []);

  // useEffect(() => {
  //    // revealClip.play();
  // }, []);

  useEffect(() => {
    if (!isConnectionOpen(wss)) {
      setWss(getConnection());
      registerResultHandler(wss);
    }
    switch (wss.readyState) {
      case WebSocket.OPEN:
        wss.send(
          JSON.stringify({
            action: "validate_session",
            group_id: groupId,
            user_id: currentPlayer,
            auth: {
              session_id: Session.get(SESSION_KEY_SESSION_ID),
            },
          })
        );
        break;
      case WebSocket.CONNECTING:
        wss.onopen = () => {
          wss.send(
            JSON.stringify({
              action: "validate_session",
              group_id: groupId,
              user_id: currentPlayer,
              auth: {
                session_id: Session.get(SESSION_KEY_SESSION_ID),
              },
            })
          );
        };
        break;
      case WebSocket.CONNREFUSED:
        alert(
          "Failed to detect a valid network connection. Check your internet connectivity, " +
            "and try again."
        );
    }
  }, []);

  useEffect(() => {
    wss.onmessage = (res) => {
      const data = JSON.parse(res.data);
      let action = data.action;
      switch (action) {
        case "validate_session":
          if (data.status === false) {
            closeConnection();
            history.push({
              pathname: "/welcome",
              state: {},
            });
          }
          break;
        default:
        // Do nothing.
      }
    };
  }, [wss]);

  useEffect(() => {
    if (!result) {
      if (revealState === STATE.NO_ANSWER_PARA1_LOADING_STARTED) {
        setTimeout(() => {
          setNoAnswerPara1Hidden(false);
          setRevealState(STATE.NO_ANSWER_PARA1_LOADED);
        }, 200);
      } else if (revealState === STATE.NO_ANSWER_PARA1_LOADED) {
        setTimeout(() => {
          setNoAnswerPara2Hidden(false);
          setRevealState(STATE.NO_ANSWER_PARA2_LOADED);
        }, 800);
      } else if (
        revealState === STATE.NO_ANSWER_PARA2_LOADED &&
        (questionType === "nice" || questionType === "naughty")
      ) {
        setTimeout(() => {
          setIsNoAnswerImageHidden(false);
          setRevealState(STATE.NO_ANSWER_NN_PARA3_LOADED);
        }, 1200);
      }
    }
  }, [revealState]);

  useEffect(() => {
    if (result) {
      document.querySelector("#rp-timer-div").innerHTML = "";
      setTimer("");
      function animation(questionType) {
        if (questionType === "nice") {
          return timerNNB10s;
        } else if (questionType === "naughty") {
          return timerNNW10s;
        } else if (questionType === "innocent") {
          return timerB10s;
        } else if (questionType === "wicked") {
          return timerW10s;
        }
      }
      lottie.loadAnimation({
        container: document.querySelector("#rp-timer-div"),
        animationData: animation(questionType),
        loop: false,
      });
    }
  }, []);

  useEffect(() => {
    registerResultHandler(wss);
  }, [wss]);

  useEffect(() => {
    let timeout;
    if (seconds > 0) {
      timeout = setTimeout(() => {
        let remaining = seconds - 1;
        setSeconds(remaining);
        Session.set(SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL, remaining);
      }, 1000);
    } else {
      Session.set(
        SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL,
        REVEAL_TIMEOUT_IN_SECONDS
      );
      if (currentQuestionId === TOTAL_NUMBER_OF_QUESTIONS) {
        history.push({
          pathname: "/outro",
          state: {
            groupId: groupId,
            currentPlayer: currentPlayer,
            isPreloadEnabled: true,
            questionType: questionType,
          },
        });
      } else {
        if (!isConnectionOpen(wss)) {
          setWss(getConnection());
          registerResultHandler(wss);
        }
        switch (wss.readyState) {
          case WebSocket.OPEN:
            wss.send(
              JSON.stringify({
                action: "get_question",
                group_id: groupId,
                currentPlayer: currentPlayer,
                auth: {
                  session_id: Session.get(SESSION_KEY_SESSION_ID),
                },
              })
            );
            break;
          case WebSocket.CONNECTING:
            wss.onopen = () => {
              wss.send(
                JSON.stringify({
                  action: "get_question",
                  group_id: groupId,
                  currentPlayer: currentPlayer,
                  auth: {
                    session_id: Session.get(SESSION_KEY_SESSION_ID),
                  },
                })
              );
            };
            break;
          case WebSocket.CONNREFUSED:
            alert(
              "Failed to detect a valid network connection. Check your internet connectivity, " +
                "and try again."
            );
            break;
          default:
          // Do nothing.
        }
      }
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [seconds]);

  function registerResultHandler(wss) {
    wss.onmessage = (res) => {
      if (res) {
        const data = JSON.parse(res.data);
        let action = data.action;
        switch (action) {
          case "get_question":
            history.push({
              pathname: "/question",
              state: {
                roomNumber: groupId,
                currentPlayer: currentPlayer,
                isLoadingDone: false,
                questionType: data.type,
              },
            });
            break;
          case "validate_session":
            if (data.status === false) {
              history.push({
                pathname: "/welcome",
                state: {},
              });
            }
            break;
          default:
          // do nothing.
        }
      }
    };
  }

  function getRoomNumber(location) {
    if (location.state && location.state.groupId) {
      return location.state.groupId;
    }
    return Session.get(SESSION_KEY_GROUP_ID) || "";
  }

  function getCurrentPlayer(location) {
    if (location.state && location.state.currentPlayer) {
      return location.state.currentPlayer;
    }
    return Session.get(SESSION_KEY_USER_ID) || "";
  }

  if (result) {
    return (
      <AnswerSegment
        questionType={questionType}
        isSegmentHidden={false}
        currentQuestion={currentQuestion}
        timer={timer}
        correctAnswerPercentage={correctAnswerPercentage}
        incorrectAnswerPercentage={incorrectAnswerPercentage}
        result={result}
        numCorrectAnswers={numberOfCorrectAnswers}
        numAnswers={numberOfAnswers}
        allUsersAnswered={allUsersAnswered}
      />
    );
  } else {
    if (ga4) {
      ga4.event(
        "whoofus_screen_no_one_answered",
        " No one answered, to the question '" + currentQuestion + "'"
      );
    }
    return (
      <NoAnswerContainer
        isNoAnswerPara1Hidden={isNoAnswerPara1Hidden}
        isNoAnswerPara2Hidden={isNoAnswerPara2Hidden}
        isNoAnswerImageHidden={isNoAnswerImageHidden}
      />
    );
  }
}

function AnswerSegment({
  questionType,
  isSegmentHidden,
  currentQuestion,
  timer,
  correctAnswerPercentage,
  result,
  numCorrectAnswers,
  numAnswers,
  allUsersAnswered,
}) {
  return (
    <div
      className={
        (questionType === "innocent" || questionType === "nice"
          ? "rp-qseg-innocent"
          : "") +
        (questionType === "wicked" || questionType === "naughty"
          ? "rp-qseg-wicked"
          : "")
      }
      hidden={isSegmentHidden}
    >
      {questionType === "innocent" || questionType === "wicked" ? (
        <TitleText questionType={questionType} />
      ) : (
        <TitleLogo questionType={questionType} />
      )}
      <Question questionType={questionType} currentQuestion={currentQuestion} />
      <TimerContainer timer={timer} />
      <Answer
        correctAnswerPercentage={correctAnswerPercentage}
        result={result}
        questionType={questionType}
      />
      <CorrectAnswerCountDescription
        answerCount={numAnswers}
        correctAnswerCount={numCorrectAnswers}
        correctAnswer={result}
        allUsersAnswered={allUsersAnswered}
      />
    </div>
  );
}

function NoAnswerContainer({
  isNoAnswerPara1Hidden,
  isNoAnswerPara2Hidden,
  isNoAnswerImageHidden,
}) {
  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-7 rp-no-answer-container">
      <NoAnswerPara1 isHidden={isNoAnswerPara1Hidden} />
      <NoAnswerPara2 isHidden={isNoAnswerPara2Hidden} />
      <NoAnswerImg isHidden={isNoAnswerImageHidden} />
    </div>
  );
}

function NoAnswerPara1({ isHidden }) {
  return (
    <div
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 rp-no-answer-para-1"
      hidden={isHidden}
    >
      Too awkward?
    </div>
  );
}

function NoAnswerPara2({ isHidden }) {
  return (
    <div
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 rp-no-answer-para-2"
      hidden={isHidden}
    >
      No one submitted an answer to that question. Let's move on and pretend
      nothing happened...
    </div>
  );
}
function NoAnswerImg({ isHidden }) {
  return (
    <div
      className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 rp-no-answer-para-3"
      hidden={isHidden}
    >
      <div className="outo-bottom-div">
        <img src={outroImg} className="bottom-img" />
      </div>
    </div>
  );
}

function Answer({ questionType, correctAnswerPercentage, result }) {
  function getAnimatedClassName() {
    const classId = 10 - Math.floor(correctAnswerPercentage / 10);
    if (classId === 0) {
      switch (questionType) {
        case "innocent":
          return " rp-sliding-anim-innocent";
          break;
        case "wicked":
          return " rp-sliding-anim-wicked";
          break;
        case "nice":
          return " rp-sliding-anim-nice";
          break;
        case "naughty":
          return " rp-sliding-anim-naughty";
          break;
        default:
        //do nothing
      }
    } else {
      switch (questionType) {
        case "innocent":
          return " rp-sliding-anim-innocent" + "-" + classId;
          break;
        case "wicked":
          return " rp-sliding-anim-wicked" + "-" + classId;
          break;
        case "nice":
          return " rp-sliding-anim-nice" + "-" + classId;
          break;
        case "naughty":
          return " rp-sliding-anim-naughty" + "-" + classId;
          break;
        default:
        //do nothing
      }
    }
  }

  return (
    <div
      className={
        "col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 " +
        (questionType === "wicked" ? "rp-answer rp-answer-wicked" : "") +
        (questionType === "innocent" ? "rp-answer rp-answer-innocent" : "") +
        (questionType === "naughty"
          ? "rp-answer-for-naughty rp-answer-naughty"
          : "") +
        (questionType === "nice" ? "rp-answer-for-nice rp-answer-nice" : "") +
        getAnimatedClassName()
      }
    >
      {result}
    </div>
  );
}

function TimerContainer({ timer }) {
  return (
    <div
      id="rp-timer-div"
      className={"col-10 col-s-10 col-sm-10 col-md-10 col-lg-8"}
    >
      {timer}
    </div>
  );
}

function TitleText({ questionType, isHidden }) {
  return (
    <div
      className={
        "col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 rp-title " +
        (questionType === "wicked" ? "rp-title-wicked " : "") +
        (questionType === "innocent" ? "rp-title-innocent " : "")
      }
      hidden={isHidden}
    >
      WHO
      <br />
      OF US
    </div>
  );
}
function TitleLogo({ questionType, isHidden }) {
  return (
    <div
      className={
        "col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 col-xl-5 rp-title "
      }
      hidden={isHidden}
    >
      {questionType === "nice" ? (
        <>
          <img src={blackLeftLeaf} className="black-left-cls" />
          <span className="rp-logo-nice">
            {" "}
            WHO
            <br />
            OF US
          </span>
          <img src={blackRightLeaf} className="black-left-cls" />
        </>
      ) : (
        <>
          <img src={whiteLeftLeaf} className="black-left-cls" />
          <span className="rp-logo-naughty">
            {" "}
            WHO
            <br />
            OF US
          </span>
          <img src={whiteRightLeaf} className="black-left-cls" />
        </>
      )}
    </div>
  );
}

function Question({ questionType, currentQuestion }) {
  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 rp-ques-cont">
      <div
        className={
          questionType === "innocent" || questionType === "wicked"
            ? "rp-question"
            : questionType === "nice"
            ? "rp-black"
            : "rp-white"
        }
      >
        {currentQuestion}
      </div>
    </div>
  );
}

function CorrectAnswerCountDescription({
  answerCount,
  correctAnswerCount,
  correctAnswer,
  allUsersAnswered,
}) {
  const [description] = useState(
    getDescription(answerCount, correctAnswerCount, correctAnswer)
  );

  function getDescription(answerCount, correctAnswerCount, correctAnswer) {
    let description = "(";
    if (correctAnswerCount === answerCount) {
      description += "It's unanimous!";
    } else if (allUsersAnswered && correctAnswerCount === 1) {
      description += `It's a split vote, but ${correctAnswer} was dobbed in first!`;
    } else {
      description +=
        "There " +
        (correctAnswerCount === 1 ? "was" : "were") +
        " " +
        correctAnswerCount +
        " " +
        (correctAnswerCount === 1 ? "vote" : "votes") +
        " for " +
        correctAnswer;
    }
    return description + ")";
  }

  return (
    <div className="col-10 col-s-10 col-sm-10 col-md-10 col-lg-8 rp-answer-count-label">
      {description}
    </div>
  );
}

export default withRouter(RevealPage);
