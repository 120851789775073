const SOUND_TRACK_HOST_URL_PREFIX =
  process.env.REACT_APP_SOUND_TRACK_HOST_URL_PREFIX || "";

export const GTM_CODE = "G-ZCE1XRD3G8";

// export const SOUND_TRACK_URL_GENERIC_TAP = "https://innocent-bystander-app-soundtracksbucket-tzpfs805umd9.s3.ap-southeast-2.amazonaws.com/whoamongus.sfx.question.button.wav";
export const SOUND_TRACK_URL_GENERIC_TAP =
  SOUND_TRACK_HOST_URL_PREFIX + "/whoamongus.sfx.question.button.wav";
//export const SOUND_TRACK_URL_INNOCENT_QUESTION = SOUND_TRACK_HOST_URL_PREFIX + "/whoamongus.sfx.question.innocent.wav";
//export const SOUND_TRACK_URL_WICKED_QUESTION = SOUND_TRACK_HOST_URL_PREFIX + "/whoamongus.sfx.question.wicked.wav";
export const SOUND_TRACK_URL_REVEAL =
  SOUND_TRACK_HOST_URL_PREFIX + "/whoamongus.sfx.reveal.name.wav";
export const SOUND_TRACK_URL_TIMER =
  SOUND_TRACK_HOST_URL_PREFIX + "/whoamongus.sfx.question.timer.wav";
export const SOUND_TRACK_URL_WINNERS =
  SOUND_TRACK_HOST_URL_PREFIX + "/whoamongus.sfx.outro.winners.wav";

//welcome-launch screen constants
export const WELCOME_MODAL_TITLE = "Welcome.";
export const WELCOME_MODAL_PROMPT =
  "Who Among Us is a game made for grown-ups. If you’re under 18, maybe you could play Uno instead?";
export const WELCOME_MODAL_BTN_OVER_18 = "I'M OVER 18. LET ME IN!";

//welcome screen constants
export const WELCOME_GAME_TITLE = "Who among us";
export const WELCOME_BRANDING = "";
export const WELCOME_BTN_NEW_GAME = "Start a new game";
export const WELCOME_BTN_JOIN_GAME = "Join a game";
export const WELCOME_TXT_BTN_CHOICE_PROMPT =
  "If you're the first player, start a new game.";
export const WELCOME_BTN_SIGN_UP = "Join our mailing list";

//start game screen constants
export const START_GAME_TITLE = "Who among us";
export const START_TITLE = "Start a new game";
export const START_TXT_ENTER_NAME = "What's your name?";
export const START_TXT_PLACEHOLDER = "My name is...";
export const START_BTN_START_GAME = "Lock it in";
export const START_TXT_INSTRUCTIONS =
  "Start a new game, and then invite your friends to join!";

//start game screen validations
export const START_VALIDATION_NAME_REQUIRED = "Nope. Your name can't be blank!";
export const START_VALIDATION_NAME_INVALID =
  "Sorry, your name needs to between 2 and 12 characters long.";

//Join game screen
export const JOIN_GAME_TITLE = "Who among us";
export const JOIN_TITLE = "Join a game";
export const JOIN_TXT_ENTER_NAME = "What's your name?";
export const JOIN_TXT_NAME_PLACEHOLDER = "My name is...";
export const JOIN_TXT_ENTER_NUMBER = "Room #?";
export const JOIN_TXT_NUMBER_PLACEHOLDER = "1234";
export const JOIN_BTN_JOIN_GAME = "Lock it in";
export const JOIN_TXT_INSTRUCTIONS =
  "Enter the room number to join a game your friend has started.";

//Join game screen validations
export const JOIN_VALIDATION_NAME_REQUIRED =
  "You need to enter your name to start a game.";
export const JOIN_VALIDATION_NAME_INVALID =
  "Please enter a valid name, from 2 to 12 characters long.";
export const JOIN_VALIDATION_NAME_INUSE =
  "This name is already in use, pick a name just for you that everyone else will recognise.";
export const JOIN_VALIDATION_ROOM =
  "Please enter a 4 digit room number. Ask the person who started the game.";
export const JOIN_VALIDATION_UNRECOGNISED_ROOM = "This room number is active.";

//Ready/Waiting screen
export const READY_GAME_TITLE = "Who among us";
export const READY_TITLE = "?";
export const READY_LBL_ROOM = "Room:";
export const READY_BTN_SHARE = "Share";
export const READY_LBL_PLAYER_JOINED = "Players joined:";
export const READY_BTN_BEGIN = "Begin the game";
export const READY_TXT_INSTRUCTIONS =
  "Wait until all the players are ready before you begin.";

//Ready screen validations
export const READY_VALIDATION_NO_PLAYERS =
  "Other players need to join the game from their device before you begin.";

//Intro screen
export const INTRO_GAME_TITLE = "";
export const INTRO_TXT_THEME = "Find out who among you is......";
export const INTRO_TXT_PLAY_INSTRUCTIONS =
  "Questions will be sent to the group.... pick one of your friends...";
export const INTRO_LBL_CTA = "Get ready!";

//Question screen
export const QUESTION_GAME_TITLE = "Who among us";
export const QUESTION_TXT_SELECT_FLAVOUR1 = "Definitely $name";
export const QUESTION_TXT_SELECT_FLAVOUR2 = "$name of course.";
export const QUESTION_TXT_SELECT_FLAVOUR3 = "That would be $name";
export const QUESTION_TXT_SELECT_FLAVOUR4 = "$name for sure.";
export const QUESTION_TXT_SELECT_FLAVOUR5 = "";
export const QUESTION_TXT_SELECT_FLAVOUR6 = "";
export const QUESTION_TXT_SELECT_FLAVOUR7 = "";
export const QUESTION_TXT_SELECT_FLAVOUR8 = "";

//Reveal screen
export const REVEAL_GAME_TITLE = "";

//Outro screen
export const OUTRO_PRELOAD_TEXT_P1 = "Well, that was ";
export const OUTRO_PRELOAD_TEXT_P2 =
  "Get ready to find out who among you is the most wicked and innocent.";
export const OUTRO_PRELOAD_FLAVOUR1 = "interesting";
export const OUTRO_PRELOAD_FLAVOUR2 = "embassing";
export const OUTRO_PRELOAD_FLAVOUR3 = "awkward";
export const OUTRO_PRELOAD_FLAVOUR4 = "educational";

export const OUTRO_GAME_TITLE = "";
export const OUTRO_TITLE = "";
export const OUTRO_TXT_THEME = "";
export const OUTRO_LBL_WICKED_WINNER = "The most wicked among us is:";
export const OUTRO_LBL_INNOCENT_WINNER = "The most innocent among us is:";
export const OUTRO_BTN_PLAY_AGAIN = "Play again";
export const OUTRO_BTN_SIGN_UP = "Sign up for more!";

export const TERMS_AND_CONDITIONS_URL =
  "https://www.innocentbystander.com.au/wp-content/uploads/sites/4/2021/09/WHO-OF-US-Terms-and-conditions-of-use-version-of-02.09.21.pdf";
export const SEND_FEEDBACK_FORM_URL = "https://www.surveymonkey.com/r/whoofus";

export const SESSION_KEY_OUTRO_STATE = "outroState";
export const SESSION_KEY_PRELOAD_ENABLED = "preloadEnabled";
export const SESSION_KEY_GROUP_ID = "groupId";
export const SESSION_KEY_USER_ID = "userId";
export const SESSION_KEY_SESSION_ID = "sessionId";
export const SESSION_KEY_IS_GROUP_LEADER = "isGroupLeader";
export const SESSION_KEY_REMAINING_SECONDS_FOR_QUESTION =
  "remainingSecondsForQuestion";
export const SESSION_KEY_REMAINING_SECONDS_FOR_REVEAL =
  "remainingSecondsForReveal";

export const SHARE_LINK_CAPTION_TEXT =
  "You’re invited to play ‘Who Of Us’, a game that separates the innocent from the wicked, by Innocent Bystander winemakers. Are you game?";

export const CHRISTMAS_THEME_START =
  process.env.REACT_APP_CHRISTMAS_THEME_START;
export const CHRISTMAS_THEME_END = process.env.REACT_APP_CHRISTMAS_THEME_END;
